import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { getRoleIcon } from 'auth/components/AuthSidebar';
import TitleTag from 'payment/components/TitleTag';

const getPageTitle = () => {
  const { pathname } = window.location;
  const titles = pathname.split('/').filter(text => text !== '');
  const reversedTitles = titles.reverse();
  const title = reversedTitles[0] ?? '';

  return title.replaceAll('-', ' ');
};

const Layout = ({ children }) => {
  const { role } = useParams();
  const title = role || getPageTitle();

  return (
    <>
      <TitleTag
        icon={getRoleIcon(title)}
        title={title}
        sx={{ display: [null, 'none'] }}
      />

      <Box __css={{ py: 'medium', px: ['medium', null, 'large'] }}>{children}</Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
