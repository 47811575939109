import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';

import { Loading, Error } from 'ui';

import InvoiceSummaryCard from 'payment/blocks/InvoiceSummaryCard';
import { INVOICE_SUMMARY_QUERY } from 'payment/queries';

const Summary = ({ contract, role }) => {
  const { loading, error, data } = useQuery(INVOICE_SUMMARY_QUERY, {
    variables: { contract, role },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const invoices = data.invoiceSummaryQuery;

  if (!invoices.length) {
    return (
      <Box bg='white' p='medium'>
        No se encuentran facturas asociadas.
      </Box>
    );
  }

  return (
    <>
      {invoices.map(invoice => (
        <InvoiceSummaryCard key={uuid()} {...invoice} />
      ))}
    </>
  );
};

Summary.propTypes = {
  contract: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default Summary;
