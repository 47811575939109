import React from 'react';
import DynamicLanding from '../../auth/components/DynamicLanding';

function SurveyLanding(props) {
  const title = 'Actualización de datos clientes propietarios';
  const phrase = 'Encuesta';

  return <DynamicLanding title={title} phrase={phrase} {...props} />;
}

export default SurveyLanding;
