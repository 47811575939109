import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import { Button, Icon } from 'ui';

export const GoogleSignInButton = ({ label, ...props }) => {
  const handleClick = () => Auth.federatedSignIn({ provider: 'Google' });

  return (
    <Button fluid onClick={handleClick} variant='plain' mb='small' {...props}>
      <Icon name='google' mr='small' />
      {label}
    </Button>
  );
};

GoogleSignInButton.defaultProps = {
  label: 'Continue with Google',
};

GoogleSignInButton.propTypes = {
  label: PropTypes.string,
};

export const FacebookSignInButton = ({ label, ...props }) => {
  const handleClick = () => Auth.federatedSignIn({ provider: 'Facebook' });
  return (
    <Button fluid onClick={handleClick} variant='plain' mb='small' {...props}>
      <Icon name='facebook' mr='xxsmall' />
      {label}
    </Button>
  );
};

FacebookSignInButton.defaultProps = {
  label: 'Continue with Facebook',
};

FacebookSignInButton.propTypes = {
  label: PropTypes.string,
};
