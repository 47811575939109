import React from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Heading } from 'theme-ui';

import { Button, Form, Field, Input, Paragraph } from 'ui';
import yup from '../located-yup';

const SignupSchema = yup.object().shape({
  email: yup.string().required(),
});

function ForgotPassword() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    validationSchema: SignupSchema,
  });

  const onSubmit = async data => {
    try {
      await Auth.forgotPassword(data.email);
      navigate('/verificar-contrasena');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      <Heading>¿Olvidó su contraseña?</Heading>

      <Paragraph>
        Para restaurar su contraseña, ingrese su dirección de correo electrónico o
        número de documento.
      </Paragraph>

      <Field
        control={Input}
        name='email'
        type='text'
        ref={register}
        placeholder='Correo electrónico o documento'
        error={errors.email}
      />

      <Button fluid disabled={isSubmitting} loading={isSubmitting} type='submit'>
        Enviar
      </Button>
    </Form>
  );
}

export default ForgotPassword;
