import { useState } from 'react';

import locations from './locations.json';

const getKeysAsSelectOptions = object =>
  Object.keys(object).map(name => ({
    value: name,
    label: name,
    meta: object[name],
  }));

const getStrAsSelectOptions = arr =>
  arr.map(name => ({
    value: name,
    label: name,
  }));

const departments = getKeysAsSelectOptions(locations);

function LocationField({ children }) {
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const updateCities = option => {
    const { meta } = option;

    setCities(getKeysAsSelectOptions(meta));
    setNeighborhoods([]);
  };

  const updateNeighborhoods = option => {
    const { meta } = option;

    setNeighborhoods(getStrAsSelectOptions(meta));
  };

  return children(
    departments,
    cities,
    neighborhoods,
    updateCities,
    updateNeighborhoods
  );
}

export default LocationField;
