import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';
import { Loading, Error } from 'ui';

import Table, { Th, Td } from '../components/Table';

import { BUSINESS_PROMOTION_QUERY } from '../queries';

const Portal = ({ code }) => {
  const { loading, error, data } = useQuery(BUSINESS_PROMOTION_QUERY, {
    variables: { code },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { sites } = data.businessPromotionQuery;

  if (!sites.length) {
    return null;
  }

  return (
    <>
      <Box as='h2'>Promoción del inmueble en otros portales</Box>
      <Table>
        <thead>
          <tr>
            <Th>Nombre del Portal</Th>
            <Th>Código del Portal</Th>
            <Th>Ver</Th>
          </tr>
        </thead>
        <tbody>
          {sites.map(site => (
            <tr key={site.code}>
              <Td>{` ${site.name}`}</Td>
              <Td>{` ${site.code}`}</Td>
              <Td>
                <Box
                  as='a'
                  sx={{ color: 'accent-2' }}
                  href={site.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Ver publicación
                </Box>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

Portal.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Portal;
