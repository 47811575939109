import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box } from 'theme-ui';

import { AuthContext } from 'auth/AuthContext';
import { Button, Form, Field, Input } from 'ui';
import validations from 'utils/validations';

function ChangePassword() {
  const navigate = useNavigate();
  // const { close } = useContext(Modal.Context)
  const auth = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    errors,
    // setError,
    formState: { isSubmitting },
  } = useForm({ validateCriteriaMode: 'all' });

  const onSubmit = async data => {
    if (auth.user) {
      return Auth.completeNewPassword(auth.user, data.password)
        .then(async user => {
          const session = await Auth.userSession(user);
          const info = await Auth.currentUserInfo();

          auth.setUserData(true, user, session, info);
        })
        .catch(e => {
          console.error(e);
        });
    }

    // If the user auth is not defined redirect user to the main page.
    return navigate('/');
  };

  const requiredMessage = 'Este campo es obligatorio';
  // @TODO: Missing passwords-match validation. msg: Las contraseñas no coinciden

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      <h2>Actualizar contraseña</h2>
      <p>Por favor ingrese su nueva contraseña.</p>

      <Box mb='medium'>
        <Field
          control={Input}
          name='password'
          type='password'
          ref={register({
            required: requiredMessage,
            validate: validations.password,
          })}
          label='Nueva contraseña'
          errors={errors}
        />

        <Field
          control={Input}
          name='confirmpassword'
          type='password'
          ref={register({
            required: requiredMessage,
          })}
          label='Confirmar contraseña'
          error={errors.confirmpassword}
        />
      </Box>

      <Button disabled={isSubmitting} loading={isSubmitting} type='submit' fluid>
        Enviar
      </Button>
    </Form>
  );
}

export default ChangePassword;
