import React from 'react';
import PropTypes from 'prop-types';

const ClientContext = React.createContext({});

export function ClientProvider({ client, children }) {
  const { permissions } = client;

  const hasPermision = permission => {
    return permissions.indexOf(permission) !== -1;
  };

  const shareSettings = {
    client,
    hasPermision,
  };

  return (
    <ClientContext.Provider value={shareSettings}>{children}</ClientContext.Provider>
  );
}

ClientProvider.defaultProps = {
  client: {
    permissions: [],
  },
};

ClientProvider.propTypes = {
  client: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  children: PropTypes.node.isRequired,
};

export default ClientContext;
