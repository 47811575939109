import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

const WelcomeHeading = ({ children, phrase, ...props }) => {
  const baseStyles = {
    fontSize: ['28px', 5],
    lineHeight: 1,
    color: 'white',
    mb: 'medium',
  };

  return (
    <Box as='h1' __css={baseStyles} {...props}>
      {phrase && (
        <Box sx={{ fontWeight: '300', fontSize: [3, 4], mb: 'xsmall' }}>
          {phrase}
        </Box>
      )}
      {children}
    </Box>
  );
};

WelcomeHeading.defaultProps = {
  phrase: undefined,
};

WelcomeHeading.propTypes = {
  phrase: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default WelcomeHeading;
