import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';
import { Loading, Error } from 'ui';

import Summary from '../components/Summary';

import { ZOHO_STATISTICS_QUERY } from '../queries';

function ZohoCharts({ code }) {
  const { loading, error, data } = useQuery(ZOHO_STATISTICS_QUERY, {
    variables: { code },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { metrics } = data.zohoStatisticsQuery;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: [null, '1fr 1fr', null, null, '1fr 1fr 1fr'],
        columnGap: 'medium',
      }}
    >
      {metrics.map(metric => (
        <Summary
          icon={metric.icon}
          label={metric.label}
          quantity={metric.value}
          key={metric.key}
        />
      ))}
    </Box>
  );
}

ZohoCharts.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ZohoCharts;
