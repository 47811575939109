import { gql } from '@apollo/client';

export const PROPERTIES_BY_OWNER_QUERY = gql`
  query PropertiesByOwner($document: String!) {
    propertiesByOwner(document: $document) {
      id
      code
      city
      address
      type
      neighborhood
      department
      url
      service
    }
  }
`;

export const ZOHO_STATISTICS_QUERY = gql`
  query ZohoStatistics($code: String!) {
    zohoStatisticsQuery(code: $code) {
      id
      code
      metrics {
        icon
        key
        label
        value
      }
    }
  }
`;

export const ANALYTIC_STATISTICS_QUERY = gql`
  query AnalyticStatistics($code: String!) {
    analyticStatisticsQuery(code: $code) {
      id
      code
      metrics {
        icon
        key
        label
        value
        dates {
          date
          amount
        }
      }
    }
  }
`;

export const BUSINESS_PROMOTION_QUERY = gql`
  query BusinessPromotion($code: String!) {
    businessPromotionQuery(code: $code) {
      id
      sites {
        name
        url
        code
      }
    }
  }
`;

export const SEARCH_STATISTICS_QUERY = gql`
  query SearchStatisticsQuery($type: String!, $search: String!, $page: Int) {
    result: searchStatisticsQuery(type: $type, search: $search, page: $page) {
      items {
        id
        code
        city
        address
        type
        neighborhood
        department
        service
      }
      pagination {
        total
        page
        limit
      }
    }
  }
`;

export const UNIVERSAL_STATISTICS_QUERY = gql`
  query UniversalStatistics($code: String!) {
    statistics: universalStatisticsQuery(code: $code) {
      zoho {
        key
        description
        icon
        label
        value
      }
      google {
        key
        description
        icon
        label
        value
        dates {
          amount
          date
        }
      }
    }
  }
`;
