import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

function Form(props) {
  return <Box as='form' {...props} />;
}

Form.propsTypes = {
  stacked: PropTypes.bool,
};

export default Form;
