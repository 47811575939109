import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';
import { Auth } from 'aws-amplify';

import { Loading, Error } from 'ui';

import AuthNav from 'auth/components/AuthNav';
import AuthSidebar from 'auth/components/AuthSidebar';
import { AuthContext } from 'auth/AuthContext';

import { CONTRACTS_QUERY } from 'payment/queries';
import { ClientProvider } from 'payment/ClientContext';
//import Alert from 'modules/maintenance/Alert';

const Home = () => {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const client = data?.clientByDocument;
  const { roles } = data?.clientByDocument ?? [];

  /*if (!client) {
    const closeAlertFn = async () => {
      await Auth.signOut();
      auth.clearUserData();
      navigate('/');
    };

    return <Alert callback={closeAlertFn} />;
  }*/

  return (
    <ClientProvider client={client}>
      <AuthNav client={client} brandTo='https://www.coninsa.co' />

      <Box __css={{ display: ['block', 'flex'], minHeight: '100vh' }}>
        <AuthSidebar
          roles={roles}
          sx={{ display: ['none', null, 'block'], width: '260px', flexShrink: 0 }}
        />

        <Box __css={{ bg: 'light-2', width: 'stretch' }}>
          {loading ? <Loading /> : <Outlet />}
        </Box>
      </Box>
    </ClientProvider>
  );
};

export default Home;
