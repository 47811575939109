import emailsAdvisors from './email-advisors.json';

export const getEmailAdviser = (request, department) => {
  const foundRequest = emailsAdvisors.requests.find(({ name }) => {
    return name === request;
  });

  if (!foundRequest) {
    return console.error(`Not found requests with this ${request} name.`);
  }

  const foundDepartment = foundRequest.departments.find(({ name }) => {
    return name === department;
  });

  if (!foundDepartment) {
    return console.error(`Not found departaments with this ${department} name.`);
  }

  return foundDepartment.email;
};
