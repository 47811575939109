import React from 'react';
import { Link } from 'react-router-dom';
import { responser } from 'libs/responser';

import { Nav } from 'ui';
import Section from 'auth/components/Section';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import AuthLanding from 'auth/components/AuthLanding';

const ChangePasswordConfirmationPageDesktop = () => (
  <>
    <Nav variant='floated'>
      <Nav.Link to='/iniciar-sesion'>Iniciar sesión</Nav.Link>
    </Nav>

    <AuthLanding>
      <div className='container'>
        <h2>Contraseña actualizada</h2>
        <p>Su contraseña ha sido actualizada satisfactoriamente!</p>
        <Link to='/'>Regresar a la página inicial</Link>
      </div>
    </AuthLanding>
  </>
);

const ChangePasswordConfirmationPage = () => {
  return (
    <>
      <Nav variant='floated'>
        <Nav.Link to='/iniciar-sesion'>
          <CloseIcon />
        </Nav.Link>
      </Nav>

      <Section>
        <div className='container'>
          <h2>Contraseña actualizada</h2>
          <p>Su contraseña ha sido actualizada satisfactoriamente!</p>
          <Link to='/'>Regresar a la página inicial</Link>
        </div>
      </Section>
    </>
  );
};

export default responser(
  ChangePasswordConfirmationPage,
  ChangePasswordConfirmationPageDesktop
);
