import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Icon } from 'ui';

const PropertyWidget = ({ icon, img, label, ...rest }) => {
  const widgetStyles = {
    display: 'flex',
    bg: 'white',
    borderRadius: '5px',
    alignItems: 'center',
    minHeight: '98px',
    px: 'medium',
    py: 'small',
    boxShadow: '0px 2px 6px #0000000A',
  };

  const labelStyles = {
    color: 'text-xweak',
    fontWeight: 'bold',
    fontSize: 1,
    textTransform: 'uppercase',
  };

  const mediaStyles = {
    flexShrink: 0,
    mr: '16px',
    width: '62px',
  };

  const imgStyles = {
    flexShrink: 0,
    mr: '16px',
  };

  return (
    <Box __css={widgetStyles} {...rest}>
      {icon && <Icon name={icon} sx={mediaStyles} />}
      {img && <Box as='img' src={img} sx={imgStyles} />}

      <Box __css={labelStyles}>{label}</Box>
    </Box>
  );
};

PropertyWidget.defaultProps = {
  icon: null,
  img: null,
};

PropertyWidget.propTypes = {
  icon: PropTypes.string,
  img: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default PropertyWidget;
