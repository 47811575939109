import React from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { Icon } from 'ui';

const Heading = ({ children, label, icon, img, ...rest }) => {
  const imgStyles = {
    flexShrink: 0,
    mr: '16px',
  };

  return (
    <Box
      __css={{
        py: 'small',
        display: ['block', 'flex'],
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      <Box
        __css={{
          alignItems: 'center',
          color: 'text-xweak',
          display: 'flex',
          fontFamily: 'Oswald',
          fontSize: '20px',
          fontWeight: '400',
          textTransform: 'uppercase',
          mb: ['medium', 0],
        }}
      >
        {icon && <Icon name={icon} mr='16px' />}
        {img && <Box as='img' src={img} sx={imgStyles} />}
        {label}
      </Box>

      {children}
    </Box>
  );
};

Heading.defaultProps = {
  children: undefined,
  icon: undefined,
  img: undefined,
};

Heading.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  img: PropTypes.string,
  children: PropTypes.node,
};

export default Heading;
