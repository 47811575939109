import gql from 'graphql-tag';

export const WEBFORM_SUBMIT = gql`
  mutation webformSubmit($values: String!) {
    submitForm(values: $values) {
      errors
      submission {
        id
      }
    }
  }
`;

export const INCOME_CERTIFICATE_CREATE = gql`
  mutation incomeCertificateCreate(
    $identityId: String!
    $contract: String!
    $url: String!
    $name: String!
    $year: String!
    $comment: String
  ) {
    incomeCertificates: createIncomeCertificateIncomeCertificate(
      input: {
        fieldIdentityId: $identityId
        fieldContract: $contract
        fieldUrl: $url
        name: $name
        fieldYear: $year
        fieldComment: $comment
      }
    ) {
      violations {
        code
        path
        message
      }
      errors
      entity {
        ... on IncomeCertificate {
          id
          fieldUrl
          name
        }
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransactionModel(
    $document: String!
    $documentType: String
    $name: String!
    $paymentReference: String!
    $contract: String!
    $phone: String
    $email: String
    $description: String!
    $iva: String
    $subtotal: String!
    $provider: String!
  ) {
    createTransactionModel(
      input: {
        document: $document
        documentType: $documentType
        name: $name
        paymentReference: $paymentReference
        contract: $contract
        email: $email
        phone: $phone
        description: $description
        iva: $iva
        subtotal: $subtotal
        provider: $provider
      }
    ) {
      violations {
        code
        message
      }
      redirectTo
      errors
    }
  }
`;
