import React from 'react';
import PropTypes from 'prop-types';
import { InfoList } from 'ui';
import { Box } from 'theme-ui';

function InvoiceModal({ data }) {
  return (
    <>
      <Box __css={{ fontWeight: '500', mb: 'medium', fontFamily: 'Oswald' }}>
        PAGO DE FACTURA CONTRATO {data.contract}
      </Box>
      <Box>
        <InfoList>
          <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'small' }}>
            Tus datos personales
          </Box>
          <InfoList.Item title='Nombre:'>{data.name}</InfoList.Item>
          <InfoList.Item title='Cédula:'>{data.document}</InfoList.Item>
          <InfoList.Item title='Teléfono:'>{data.phone}</InfoList.Item>
          <InfoList.Item title='Correo electrónico:'>{data.email}</InfoList.Item>
        </InfoList>

        <InfoList>
          <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'small' }}>
            Infomación del pago de la factura
          </Box>
          <InfoList.Item title='Identificación del pago:'>
            {data.paymentReference}
          </InfoList.Item>
          <InfoList.Item title='Concepto del pago:'>
            {data.description}
          </InfoList.Item>
          <InfoList.Item title='IVA:'>{data.iva}</InfoList.Item>
          <InfoList.Item title='Total con IVA:'>{data.subtotal}</InfoList.Item>
        </InfoList>
      </Box>
    </>
  );
}

InvoiceModal.defaultProps = {
  data: {
    description: '',
  },
};

InvoiceModal.propTypes = {
  data: PropTypes.shape({
    contract: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    paymentReference: PropTypes.string.isRequired,
    description: PropTypes.string,
    iva: PropTypes.string,
    subtotal: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default InvoiceModal;
