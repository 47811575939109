import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Sidebar } from 'ui';
import ClientContext from 'payment/ClientContext';

const BUYER_ROLE = 'comprador';

// @TODO: Move on an external file,
export const getRoleIcon = id => {
  const rolesIconMap = {
    apoderado: 'handshake',
    arrendatario: 'file-contract',
    coarrendatario: 'user-friend',
    propietario: 'fa-home',
    solicitante: 'handshake',
    mantenimiento: 'fa-tools',
  };

  return rolesIconMap[id] || '';
};

function AuthSidebar({ roles, ...props }) {
  const { hasPermision } = useContext(ClientContext);
  const isAllowed = hasPermision('statistics');
  const hasDocumentation = hasPermision('documentation');
  const filteredRoles = roles.filter(role => role.id !== BUYER_ROLE)

  return (
    <Sidebar {...props}>
      {filteredRoles.map(role => {
        return (
          <Sidebar.Link
            key={role.id}
            to={`${role.id}/`}
            icon={getRoleIcon(role.id)}
            title={role.label}
          />
        );
      })}

      {hasDocumentation && (
        <Sidebar.Link
          key='documentation'
          to='documentacion'
          icon='fa-file-signature'
          title='Documentación'
          end
        />
      )}

      {isAllowed && (
        <Sidebar.Link
          key='statistics'
          to='estadisticas'
          icon='fa-chart-bar'
          title='Estadísticas'
        />
      )}

      <Sidebar.Link
        key='properties'
        to='mis-inmuebles'
        icon='bi-building'
        title='Mis inmuebles'
      />

      <Sidebar.Link icon='fa-tools' to='requerimientos'>
        Requerimientos
      </Sidebar.Link>
    </Sidebar>
  );
}

AuthSidebar.defaultProps = {
  roles: [],
};

AuthSidebar.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default AuthSidebar;
