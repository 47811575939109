import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Icon, Nav, Sidebar } from 'ui';
import ClientContext from 'payment/ClientContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Layer from './Layer';

import { getRoleIcon } from 'auth/components/AuthSidebar';

const BUYER_ROLE = 'comprador';

const HamburgerMenu = ({ roles }) => {
  const [showLayer, setShowLayer] = React.useState(false);

  const { hasPermision } = useContext(ClientContext);
  const isAllowed = hasPermision('statistics');
  const filteredRoles = roles.filter(role => role.id !== BUYER_ROLE)

  return (
    <Box
      __css={{
        display: ['flex', null, 'none'],
        alignItems: 'center',
        justifyContent: 'center',
        p: 'small',
      }}
    >
      <Icon name='bars' onClick={() => setShowLayer(true)} />

      {showLayer && (
        <Layer>
          <Nav background='#80C342'>
            <CloseIcon onClick={() => setShowLayer(false)} />
          </Nav>

          <Sidebar>
            {filteredRoles.map(role => {
              return (
                <Sidebar.Link
                  key={role.id}
                  to={`${role.id}/`}
                  icon={getRoleIcon(role.id)}
                  title={role.label}
                  onClick={() => {
                    setShowLayer(false);
                  }}
                />
              );
            })}

            {isAllowed && (
              <Sidebar.Link
                key='statistics'
                to='estadisticas'
                icon='fa-chart-bar'
                title='Estadísticas'
                onClick={() => {
                  setShowLayer(false);
                }}
              />
            )}
            <Sidebar.Link
              key='properties'
              to='mis-inmuebles'
              icon='bi-building'
              title='Mis inmuebles'
              onClick={() => {
                setShowLayer(false);
              }}
            />

            <Sidebar.Link
              icon='fa-tools'
              to='requerimientos'
              onClick={() => {
                setShowLayer(false);
              }}
            >
              Requerimientos
            </Sidebar.Link>
          </Sidebar>
        </Layer>
      )}
    </Box>
  );
};

HamburgerMenu.defaultProps = {
  roles: [],
};

HamburgerMenu.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default HamburgerMenu;
