import React, { lazy } from 'react';

const SearchPage = lazy(() => import('./pages/search'));
const ResultsPage = lazy(() => import('./pages/results'));

const routes = [
  {
    path: 'adelantar-canon',
    element: <SearchPage />,
  },

  {
    path: 'adelantar-canon/:document',
    element: <ResultsPage />,
  },
];

export default routes;
