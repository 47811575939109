import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify, I18n } from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';

import config from './config';

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,

    authenticationFlowType: 'USER_PASSWORD_AUTH',

    oauth: {
      domain: config.cognito.DOMAIN,
      redirectSignIn: config.cognito.REDIRECT_SIGN_IN,
      redirectSignOut: config.cognito.REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },

  Storage: {
    bucket: config.bucket.NAME,
    level: config.bucket.LEVEL,
    region: config.bucket.REGION,
  },
});

// export function SetS3Config() {
//   Storage.configure({
//     bucket: config.bucket.NAME,
//     level: config.bucket.LEVEL,
//     region: config.bucket.REGION,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID,
//   })
// }

I18n.setLanguage('es');

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
