import React, { lazy } from 'react';

import IndexPage from './pages/index';

const IntroPage = lazy(() => import('./pages/intro'));
const UpdateInfo = lazy(() => import('./pages/update-info'));
const ThanksPage = lazy(() => import('./pages/thanks'));
const CheckDocumentForm = lazy(() => import('./forms/CheckDocument'));
const CheckEmailForm = lazy(() => import('./forms/CheckEmail'));
const ContactInformationForm = lazy(() => import('./forms/ContactInformation'));

const routes = [
  {
    path: 'actualizacion-propietarios',
    element: <IndexPage />,
    children: [
      {
        path: '',
        element: <IntroPage />,
      },
      {
        path: 'indentidad',
        element: <CheckDocumentForm />,
      },
      {
        path: 'correo',
        element: <CheckEmailForm />,
      },
      {
        path: 'datos-personales',
        element: <UpdateInfo />,
      },
      {
        path: 'datos-contacto',
        element: <ContactInformationForm />,
      },
      {
        path: 'gracias',
        element: <ThanksPage />,
      },
    ],
  },
];

export default routes;
