const config = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,

    DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,

    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },

  bucket: {
    NAME: process.env.REACT_APP_BUCKET_NAME,
    REGION: process.env.REACT_APP_BUCKET_REGION,
    LEVEL: process.env.REACT_APP_BUCKET_LEVEL,
  },

  drupal: {
    SERVER_URL: process.env.REACT_APP_DRUPAL_SERVER_URL,
    API_URL: process.env.REACT_APP_DRUPAL_API_URL,
    GRAPHQL_URL: process.env.REACT_APP_DRUPAL_GRAPHQL_URL,
  },

  epayco: {
    PUBLIC_KEY: process.env.REACT_APP_EPAYCO_PUBLIC_KEY,
    TEST_MODE: process.env.REACT_APP_EPAYCO_TEST_MODE,
    RESPONSE_URL: process.env.REACT_APP_EPAYCO_RESPONSE_URL,
    CONFIRM_URL: process.env.REACT_APP_EPAYCO_CONFIRM_URL,
  },
};

export default config;
