import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { AuthContext } from 'auth/AuthContext';
import Link from 'payment/components/Link';

import { Collapse, Divider, Loading, Error } from 'ui';

import Heading from 'payment/components/Heading';
import Layout from 'payment/components/Layout';

import { CONTRACTS_QUERY } from 'payment/queries';

import Summary from './Summary';

const History = () => {
  const { role } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { contracts, name } = data.clientByDocument;

  const filteredContracts = contracts.filter(contract => {
    return contract.role === role;
  });

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />
      <Divider />
      <Heading icon='certificates-round' label='Certificados y constancias' />
      <Collapse variant='greening'>
        {filteredContracts.map(contract => (
          <Collapse.Item
            title={`Contrato: ${contract.number} | ${name} | ${contract.property.address} | ${contract.property.neighborhood} | ${contract.property.city}`}
            key={contract.number}
          >
            <Summary contract={contract.number} role={role} />
          </Collapse.Item>
        ))}
      </Collapse>
    </Layout>
  );
};

export default History;
