import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Input, Textarea, Spinner } from 'theme-ui';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';

import { Button, Field, Select, InputMask } from 'ui';
import Toast from 'ui/Toast';
import validations from 'utils/validations';

import LocationField from 'payment/blocks/LocationField';
import TermsAndConditions from '../../components/TermsAndConditions';
import { WEBFORM_SUBMIT } from '../../mutations';

import { getEmailAdviser } from './misc';

const ProcessRequireSchema = yup.object().shape({
  type: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('Seleccione un tipo de solicitud'),
  name: yup.string().required('Su nombre es obligatorio'),
  document: yup.string().required('Su cedula es obligatoria'),
  phone: validations.phone,
  email: validations.email,
  department: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required('Su departamento es obligatorio'),
});

const options = [
  { value: 'Servicios públicos', label: 'Servicios públicos' },
  {
    value: 'Solicitud de certificado de retenciones',
    label: 'Solicitud de certificado de retenciones',
  },
  {
    value: 'Terminación del contrato de arrendamiento',
    label: 'Terminación del contrato de arrendamiento',
  },
  {
    value: 'Terminación del contrato de mandato',
    label: 'Terminación del contrato de mandato',
  },
];

const defaultValues = {
  type: '',
  phone: '',
  department: '',
  city: '',
  neighborhood: '',
};

const ProcessRequireForm = () => {
  const { setToast } = useContext(Toast.Context);
  const {
    control,
    errors,
    formState: { isSubmitting, dirty },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    validationSchema: ProcessRequireSchema,
  });

  const [webformSubmit] = useMutation(WEBFORM_SUBMIT);

  const successSubmitMessage = {
    title: 'Gracias!',
    description: 'Su información fue enviada con éxito.',
    backgroundColor: '#85bb25',
    icon: 'success',
  };

  const onSubmit = async data => {
    const {
      name,
      email,
      phone,
      address,
      document,
      department,
      city,
      neighborhood,
      type,
      comments,
    } = data;

    const location = [department, city, neighborhood]
      .filter(item => Boolean(item))
      .map(item => item.value)
      .join(', ');

    const normalizedData = {
      full_name: name,
      email,
      phone,
      address,
      document,
      location,
      department: department.value,
      request_requirement: type.value,
      comments,
      email_advisor: getEmailAdviser(type.value, department.value),
      webform_id: 'process',
    };

    const webformData = JSON.stringify(normalizedData);

    return new Promise((resolve, reject) => {
      webformSubmit({
        variables: {
          values: webformData,
        },
      })
        .then(response => {
          const { errors: errorsForm } = response.data.submitForm;

          if (errorsForm.length) {
            console.error(errorsForm);
            return;
          }

          reset();
          setToast(successSubmitMessage);
          resolve(response);
        })
        .catch(error => {
          console.error(error.message);
          reject(error);
        });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        Diligencia el formulario completamente para asegurarnos de brindarte el mejor
        servicio y comunicarnos contigo.
      </p>

      <Field error={errors.type}>
        <Controller
          as={<Select />}
          control={control}
          isClearable
          name='type'
          onChange={([selected]) => selected}
          options={options}
          placeholder='¿Qué solicitud quiere realizar?'
        />
      </Field>

      <Box sx={{ display: [null, 'flex'] }}>
        <Field
          sx={{ width: [null, '70%'], mr: [null, 'small'] }}
          error={errors.name}
        >
          <Input
            placeholder='Tu nombre completo'
            name='name'
            type='text'
            ref={register}
          />
        </Field>

        <Field sx={{ width: [null, '30%'] }} error={errors.document}>
          <Input
            placeholder='Tu cédula'
            name='document'
            type='text'
            ref={register}
          />
        </Field>
      </Box>

      <Box sx={{ display: [null, 'flex'] }}>
        <Field
          sx={{ width: [null, '50%'], mr: [null, 'small'] }}
          error={errors.phone}
        >
          <Controller
            as={<InputMask />}
            control={control}
            format='+57 (###) ###-####'
            mask='_'
            name='phone'
            placeholder='Teléfono de contacto'
            type='tel'
          />
        </Field>

        <Field sx={{ width: [null, '50%'] }} error={errors.email}>
          <Input
            placeholder='Tu correo electrónico'
            name='email'
            type='email'
            ref={register}
          />
        </Field>
      </Box>

      <LocationField>
        {(departments, cities, neighborhoods, updateCities, updateNeighborhoods) => (
          <>
            <Field error={errors.department}>
              <Controller
                as={<Select />}
                control={control}
                name='department'
                isClearable
                options={departments}
                placeholder='¿En qué departamento estás ubicado?'
                onChange={([selected]) => {
                  setValue('city', null);
                  setValue('neighborhood', null);

                  if (selected) {
                    updateCities(selected);
                  }

                  return selected || null;
                }}
              />
            </Field>

            <Box sx={{ display: [null, 'flex'] }}>
              <Field sx={{ width: [null, '50%'], mr: [null, 'small'] }}>
                <Controller
                  as={<Select />}
                  control={control}
                  name='city'
                  isClearable
                  placeholder='¿En qué ciudad estás ubicado?'
                  options={cities}
                  isDisabled={!dirty || !cities.length}
                  onChange={([selected]) => {
                    setValue('neighborhood', null);

                    if (selected) {
                      updateNeighborhoods(selected);
                    }
                    return selected || null;
                  }}
                />
              </Field>

              <Field sx={{ width: [null, '50%'] }}>
                <Controller
                  as={<Select />}
                  options={neighborhoods}
                  name='neighborhood'
                  isClearable
                  control={control}
                  placeholder='¿En qué barrio estás ubicado?'
                  isDisabled={!dirty || !neighborhoods.length}
                  onChange={([selected]) => {
                    return selected;
                  }}
                />
              </Field>
            </Box>
          </>
        )}
      </LocationField>

      <Field>
        <Input
          placeholder='Dirección del inmueble'
          name='address'
          type='text'
          ref={register}
        />
      </Field>

      <Field>
        <Textarea
          rows={5}
          placeholder='Escribe los detalles de tu solicitud'
          name='comments'
          ref={register}
        />
      </Field>

      <TermsAndConditions />

      <Button sx={{ width: ['100%', 'auto'] }} disabled={isSubmitting}>
        {isSubmitting ? (
          <Spinner variant='styles.spinner' size='20px' />
        ) : (
          'Solicitar servicio'
        )}
      </Button>
    </form>
  );
};

export default ProcessRequireForm;
