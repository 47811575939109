import React, { useContext } from 'react';
import { Link as LinkRouter, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import uuid from 'react-uuid';
import { Box } from 'theme-ui';

import { AuthContext } from 'auth/AuthContext';
import Layout from 'payment/components/Layout';

import Widget from 'payment/components/Widget';
import { Loading, Error } from 'ui';
import { CONTRACTS_QUERY } from 'payment/queries';
import JuanchoPresta from 'assets/juancho-presta.png';
import Invoices from './Invoices';

const OWNER_ROLE = 'propietario';
const TENANT_ROLE = 'arrendatario';
const PROJECT_ROLE = 'proyecto';

const Dashboard = () => {
  const { role } = useParams();
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { contracts } = data.clientByDocument;

  const filteredContracts = contracts.filter(contract => {
    return contract.role === role;
  });

  return (
    <Layout>
      {role === TENANT_ROLE &&
        filteredContracts.map(contract => (
          <Invoices key={uuid()} contract={contract.number} role={role} />
        ))}
      <Box
        __css={{
          columnGap: 'medium',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          rowGap: 'small',
        }}
      >
        {role === TENANT_ROLE && (
          <Widget
            as={LinkRouter}
            icon='document-round'
            label='Contratos asociados'
            to='contratos-asociados'
          />
        )}

        {role === OWNER_ROLE && (
          <Widget
            as={LinkRouter}
            icon='document-round'
            label='Propiedades asociadas'
            to='propiedades-asociadas'
          />
        )}

        {role === PROJECT_ROLE && (
          <Widget
            as={LinkRouter}
            icon='certificates-round'
            label='Certificados y constancias'
            to='certificados-constancias'
          />
        )}

        {role !== PROJECT_ROLE && (
          <>
            <Widget
              as={LinkRouter}
              icon='bill-round'
              label='Historial de facturas'
              to='facturas/historial'
            />

            <Widget
              as={LinkRouter}
              icon='certificates-round'
              label='Certificados y constancias'
              to='certificados'
            />
          </>
        )}

        {role === OWNER_ROLE && (
          <Widget
            as={LinkRouter}
            icon='document-round-yellow'
            label='Actualización de datos'
            to='/actualizacion-propietarios'
          />
        )}
        <Widget
          as='a'
          icon='help-round'
          label='¿Necesitas ayuda?'
          href='https://www.coninsa.co/preguntas-frecuentes-mi-coninsa-virtual'
          target='_blank'
        />
        {role === OWNER_ROLE && (
          <Widget
            as='a'
            icon='document-round'
            label='Comunicados'
            href='https://www.coninsa.co/personas/comunicados-arrendamientos-propietarios-apoderados'
            target='_blank'
          />
        )}
        {role === TENANT_ROLE && (
          <Widget
            as='a'
            icon='document-round'
            label='Comunicados'
            href='https://www.coninsa.co/personas/comunicados-arrendamientos-arrendatarios-coarrendatarios'
            target='_blank'
          />
        )}
      </Box>
    </Layout>
  );
};

export default Dashboard;
