import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Loading, Error } from 'ui';
import { AuthContext } from 'auth/AuthContext';
import { CONTRACTS_QUERY } from 'payment/queries';

const BUYER_ROLE = 'comprador';

const Page = () => {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const preferredRedirectByRole = {
    [BUYER_ROLE]: '/virtual/mis-inmuebles/',
  };

  const { data, loading, error } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { roles } = data.clientByDocument;

  if (roles.length) {
    const firstRoleId = roles[0].id;

    const redirectTo = preferredRedirectByRole?.[firstRoleId]
      ? preferredRedirectByRole[firstRoleId]
      : `/virtual/${firstRoleId}/`;

    return <Navigate to={redirectTo} />;
  }

  return <>You do not have a SINCO role.</>;
};

export default Page;
