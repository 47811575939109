import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'theme-ui';

import saveAs from 'utils/saveAs';
import { Button, Card, Icon } from 'ui';
import TitleWithIcon from '../../../components/TitleWithIcon';

function GeneralDocumentCard({ name, description, url }) {
  return (
    <Card
      sx={{
        display: ['block', 'flex'],
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <TitleWithIcon mb={description ? 'small' : 0} icon='pdf' label={name} />
        {description && <Text>{description}</Text>}
      </Box>

      <Box sx={{ flexShrink: 0 }}>
        <Button
          onClick={() => saveAs(url, name)}
          round
          size='mediumSmall'
          variant='secondary'
        >
          <Icon name='fa-download' mr='xsmall' />
          Descargar
        </Button>
      </Box>
    </Card>
  );
}

GeneralDocumentCard.defaultProps = {
  description: '',
};

GeneralDocumentCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default GeneralDocumentCard;
