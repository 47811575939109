import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';

import { Loading, Error } from 'ui';
import GeneralDocumentCard from './containers/GeneralDocumentCard';
import { GENERAL_DOCUMENTS_QUERY } from './queries';

function GeneralDocuments() {
  const { loading, error, data } = useQuery(GENERAL_DOCUMENTS_QUERY);

  if (loading) return <Loading />;
  if (error) return <Error />;

  const documents = data.generalDocumentsQuery;

  return (
    <>
      {documents.map(doc => (
        <Box>
          <GeneralDocumentCard {...doc} />
        </Box>
      ))}
    </>
  );
}

export default GeneralDocuments;
