import { gql } from '@apollo/client';

export const CONTRACTS_QUERY = gql`
  query CurrentClientProperties($document: String!) {
    clientByDocument(document: $document) {
      id
      idType
      name
      email
      phone
      roles {
        id
        label
      }

      permissions

      contracts {
        id
        role
        canon
        number
        type
        state

        monthsWarranty
        endDate
        startDate
        payDay
        rates
        property {
          address
          city
          neighborhood
          type
        }
      }

      deliveredProperties {
        id
        name
        machineName
        projectId
        projectType
        state
      }

      belongsTo {
        tenant {
          proofs {
            id
            title
            url
          }
        }

        owner {
          proofs {
            id
            title
            url
          }
        }
      }

      buyerGroups {
        id
        mainName
        secondaryName
        orderNumber
        feePayable {
          id
          concept
          formattedTotal
          total
          date
          details {
            id
            concept
            formattedValue
          }
        }
        paymentPlans {
          id
          concept
          conceptType
          agreedDate
          lastPaymentDate
          agreedValue
          valuePaid
          balance
          daysArrears
          isPaid
          expiresIn5Days
          isExpired
          formattedAgreedValue
        }
        paymentSummary {
          paidValue
          payableValue
          totalBalance
          totalDaysArrears
        }
        procedures {
          code
          name
          complianceDate
          commitmentDate
        }
        units {
          id
          name
          projectId
          unitType
          formattedValue
          state
          privateArea
          builtArea
        }
      }
    }
  }
`;

export const INVOICE_QUERY = gql`
  query InvoiceQuery($contract: String!, $role: String) {
    invoiceQuery(contract: $contract, role: $role) {
      billUrl
      description
      contract
      chainFlyerUrl
      paymentAmount
      propertyAddress
      paymentReference
      iva
    }
  }
`;

export const INVOICE_SUMMARY_QUERY = gql`
  query InvoiceSummaryQuery($contract: String!, $role: String) {
    invoiceSummaryQuery(contract: $contract, role: $role) {
      contractNumber
      description
      url
    }
  }
`;

export const LAST_CERTIFICATE_QUERY = gql`
  query LastCertificateQuery($document: String!) {
    lastCertificateQuery(document: $document) {
      id
      contract
      document
      url
      year
    }
  }
`;

export const DOCUMENT_QUERY = gql`
  query DocumentQuery($contract: String!, $role: String!) {
    documentQuery(contract: $contract, role: $role) {
      contract
      url
      id
      type
      typeDetail
    }
  }
`;

export const INCOME_CERTIFICATE_QUERY = gql`
  query IncomeCertificateQuery($contracts: [String]) {
    incomeCertificateQuery(
      filter: {
        conditions: [{ field: "field_contract", value: $contracts, operator: IN }]
      }
      sort: [{ field: "field_year" }]
    ) {
      ... on EntityQueryResult {
        entities {
          ... on IncomeCertificate {
            id
            identityId: fieldIdentityId
            url: fieldUrl
            year: fieldYear
            contract: fieldContract
            comment: fieldComment
          }
        }
      }
    }
  }
`;

export const ACCOUNT_STATEMENT_QUERY = gql`
  query AccountStatement(
    $contract: String!
    $document: String!
    $year: String
    $month: String
  ) {
    accountStatementQuery(
      contract: $contract
      document: $document
      year: $year
      month: $month
    ) {
      url
      year
      month
    }
  }
`;
export const HAS_PENDING_TRANSACTIONS_QUERY = gql`
  query HasPendingTransactionsQuery(
    $paymentReference: String!
    $document: String!
    $timestamp: String!
  ) {
    hasPendingTransactions(
      paymentReference: $paymentReference
      document: $document
      timestamp: $timestamp
    ) {
      id
      message
      isTrue
    }
  }
`;

export const GET_OWNER_CANON_URL_QUERY = gql`
  query GetOwnerCanonUrl($contract: String!, $document: String!) {
    url: getOwnerCanonUrl(contract: $contract, document: $document)
  }
`;
