import React from 'react';

import Layout from 'payment/components/Layout';
import Heading from 'payment/components/Heading';
import Statistics from './pages/statistics';

const App = () => {
  return (
    <Layout>
      <Heading icon='bill-round' label='Estadísticas' />
      <Statistics />
    </Layout>
  );
};

export default App;
