import { Global, css } from '@emotion/react'
import { normalize } from 'react-style-reset/string';

export const GlobalStyle = () => {
  return (
    <Global styles={css`
      ${normalize};

      html {
        height: 100%;
      }

      body {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        min-height: 100%;
      }

      a {
          text-decoration: none;
        }
      `}
    />
  )
}

// shadow24: 'rgba(0, 0, 0, 0.24)',

const colors = {
  brand: '#F9CC00',
  background: 'white',
  green: '#A3DD26',
  'accent-1': '#005FE2',
  'accent-2': '#3dacf4',

  primary: '#F9CC00',
  secondary: '#80C342',

  // 'dark-1': '#333333',
  // 'dark-2': '#555555',
  // 'dark-3': '#6D6D6D',
  // 'dark-4': '#707070',
  'dark-4': '#818181',
  // 'dark-5': '#95989A',

  border: '#d9dadb',
  'border-light': '#e7e7e7',
  'border-lighter': '#f0f0f0',

  'light-1': '#FDFDFD',
  'light-2': '#FAFAFA',
  'light-3': '#ECECEC',

  'neutral-1': '#00993B',
  'neutral-2': '#273475',

  'dove-gray': '#707070',
  'dark-blue': '#273475',

  iron: '#D0D1D1',

  text: '#3E3E3E',
  'text-strong': '#000000',
  'text-weak': '#555555',
  'text-xweak': '#6D6D6D',

  'status-alert': '#f82e3a',
  'status-critical': '#FF4040',

  'status-error': 'hsl(0deg 100% 63%)',
  'status-error-text': 'hsl(0deg 100% 50%)',

  'status-warning': '#ea7311',
  'status-ok': '#009688',
  'status-unknown': '#CCCCCC',
  'status-disabled': '#CCCCCC',

  'coninsa-yellow': '#fbc200',
  'coninsa-green': '#74bf20',
  'coninsa-red': '#fe0f15',

  'coninsa-warning': '#d8b234',
  'coninsa-error': '#cc3d3d',
};

const baseSpacing = 24;
const scale = 12;

const fontSizing = factor => {
  const baseFontSize = Math.floor(baseSpacing * 0.667); // 16
  const fontScale = baseSpacing / scale; // 2

  return {
    fontSize: `${baseFontSize + factor * fontScale}px`,
    lineHeight: `${baseSpacing + factor * fontScale}px`,
    // maxWidth chosen to be ~50 characters wide
    // see: https://ux.stackexchange.com/a/34125
    maxWidth: `${baseSpacing * (baseFontSize + factor * fontScale)}px`,
  };
};

const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

const space = {
  none: 0,
  xxsmall: baseSpacing / 8, // 3px
  xsmall: baseSpacing / 4, // 6px
  small: baseSpacing / 2, // 12px
  medium: baseSpacing, // 24px
  large: baseSpacing * 2, // 48px
  xlarge: baseSpacing * 4, // 96px
  xxlarge: baseSpacing * 8, // 192px
};

const sizes = {
  xxsmall: baseSpacing * 2, // 48px
  xsmall: baseSpacing * 4, // 96px
  small: baseSpacing * 8, // 192px
  medium: baseSpacing * 16, // 384px
  large: baseSpacing * 32, // 768px
  xlarge: baseSpacing * 48, // 1152px
  xxlarge: baseSpacing * 64, // 1536px
  full: '100%',

  container: baseSpacing * 48,
};

const customTheme = {
  // Scales
  breakpoints: ['40em', '52em', '60em', '75em'],
  colors,

  fonts: {
    body: 'Roboto, "Helvetica Neue", sans-serif',
    heading: 'Oswald',
    monospace: 'Menlo, monospace',
  },

  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },

  lineHeights: {
    input: 1,
    body: 1.5,
    heading: 1.125,
  },

  space,
  sizes,

  // fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],

  // Components
  backdrops: {
    default: {
      layer: {
        opacity: '.25',
      },
      content: {},
    },
    fullScreen: {
      layer: {
        opacity: '.25',
      },
      content: {
        top: 0,
        position: ['absolute', 'relative'],
      },
    },
  },

  buttons: {
    primary: {
      bg: 'primary',
      borderColor: 'primary',
      color: 'dark-blue',
    },

    secondary: {
      bg: 'white',
      color: 'dark-blue',
    },

    plain: {
      bg: 'white',
      fontWeight: '400',
      textTransform: 'inherit',
    },
  },

  cards: {
    default: {
      bg: 'white',
    },
  },

  collapses: {
    default: {
      header: {
        color: 'text',
      },
      icon: {},
      content: {
        bg: 'transparent',
      },
    },
    mini: {
      header: {
        color: 'accent-1',
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 'bold',
      },
      icon: {},
      content: {
        bg: 'background',
      },
    },
    whitening: {
      header: {
        alignItems: 'center',
        boxShadow: '0px 2px 6px #0000000A',
        bg: 'background',
        borderRadius: 6,
        py: 'small',
        px: 'medium',
      },
      icon: {
        fontSize: 20,
        color: 'dark-blue',
      },
      content: {},
    },
    greening: {
      header: {
        bg: 'neutral-1',
        borderRadius: 6,
        color: 'background',
        fontFamily: 'Oswald',
        p: 'small',
        textTransform: 'uppercase',
      },
      icon: {
        fontSize: 25,
      },
      content: {},
    },
  },

  description_list: {
    default: {
      display: [null, 'flex'],
    },
    stacked: {
      description: {
        display: [null, 'flex'],
      },
    },
  },

  dropdowns: {
    default: {
      main: {
        pr: 'large',
        pl: 'medium',

        '&:hover': {
          bg: 'grey1',
        },
        arrow: {},
      },
      item: {
        pr: 'medium',
        pl: 'medium',

        '&:hover': {
          bg: 'grey2',
        },
      },
      content: {
        bg: 'background',
      },
    },
    navItem: {
      main: {
        pr: [null, '34px'],
        '&:hover': {
          bg: 'grey1',
        },
        arrow: {
          display: ['none', 'inherit'],
        },
      },
      item: {
        px: 'medium',
        '&:hover': {
          bg: 'grey2',
        },
      },
      content: {
        bg: 'background',
      },
    },
  },

  forms: {
    checkbox: {
      alignItems: 'center',
      'input:checked ~ &': {
        color: 'neutral-2',
      },
      'input:focus ~ &': {
        color: 'neutral-2',
        bg: 'highlight',
      },
    },

    label: {
      mb: 'xxsmall',
      opacity: 0.75,
    },

    select: {
      p: 'small',
      borderColor: 'border',
      lineHeight: 'input',
    },

    input: {
      p: 'small',
      borderColor: 'border',
      lineHeight: 'input',

      '&[type=file]': {
        py: 'xsmall',
      },
      '&:disabled': {
        opacity: '0.5',
      },
    },

    textarea: {
      borderColor: 'border',
      lineHeight: 'input',
      p: 'small',
    },
  },

  messages: {
    'text-error': {
      color: 'status-error',
    },

    'text-warning': {
      color: 'status-error',
    },

    error: {
      padding: 'small',
      borderTop: '1px solid',
      borderTopColor: 'status-error',
      borderBottom: '1px solid',
      borderBottomColor: 'status-error',

      color: 'status-error-text',
    },

    warning: {
      color: 'status-warning',
      fontSize: 2,
    },

    feedback: {
      fontSize: 2,
      fontWeight: 300,
      textAlign: 'center',
      justifyContent: 'center',
      p: 'medium',
    },
  },

  modals: {
    default: {
      width: '416px',
      bg: 'background',
      header: {},
    },
    fullScreen: {
      width: '416px',
      bg: 'background',
    },
  },

  navs: {
    default: {
      bg: 'white',
      content: {},
    },
    floated: {
      boxShadow: 'none',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
    },
    fluid: {
      bg: 'white',
      content: {
        width: '100%',
      },
    },
  },

  links: {
    default: {
      svg: {
        mr: 'small',
      },
    },

    reverse: {
      flexDirection: 'row-reverse',
      svg: {
        ml: 'small',
      },
    },

    light: {
      fontWeight: 300,
      fontSize: 1,
      color: 'white',
    },
  },

  text: {
    xsmall: { ...fontSizing(-2) }, // 12px
    small: { ...fontSizing(-1) }, // 14px
    medium: { ...fontSizing(0) }, // 16px
    large: { ...fontSizing(2) }, // 20px
    xlarge: { ...fontSizing(4) }, // 24px
    xxlarge: { ...fontSizing(8) }, // 32px

    heading: {
      fontFamily: 'body',
      fontWeight: 'normal',
      mb: 'medium',
    },
  },

  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: 5,
    },
    h2: {
      ...heading,
      fontSize: 4,
    },
    h3: {
      ...heading,
      fontSize: 3,
    },
    h4: {
      ...heading,
      fontSize: 2,
    },
    h5: {
      ...heading,
      fontSize: 1,
    },
    h6: {
      ...heading,
      fontSize: 0,
    },

    hr: {
      my: 'small',
      color: 'border-light',
    },

    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
};

export default customTheme;
