import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'theme-ui';
import { Icon } from 'ui';

const TitleWithIcon = ({ icon, label, ...rest }) => {
  return (
    <Box __css={{ display: 'flex', mb: '15px', alignItems: 'center' }} {...rest}>
      <Icon sx={{ flexShrink: 0, mr: '10px' }} name={icon} />
      <Text
        __css={{
          color: 'neutral-2',
          fontSize: '15px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {label}
      </Text>
    </Box>
  );
};

TitleWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default TitleWithIcon;
