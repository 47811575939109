import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Storage } from 'aws-amplify';
import { Input, TextArea, Loading, Error, Message } from 'ui';
import { Select } from 'theme-ui';

import { AuthContext } from 'auth/AuthContext';
import Form from 'ui/Form';
// import Error from 'ui/Message'
import Field from 'ui/Field';

import Button from 'ui/Button';
import Toast from 'ui/Toast';

import { INCOME_CERTIFICATE_CREATE } from 'payment/mutations';
import { CONTRACTS_QUERY } from 'payment/queries';
import uuid from 'react-uuid';

const date = new Date();
const year = date.getFullYear();

const defaultValues = {
  year: year - 1,
};

const getLocation = contract => {
  const { address, neighborhood, city } = contract;
  const strings = [city, neighborhood, address];
  const complement = strings
    .filter(string => {
      return string;
    })
    .join(', ');
  return complement;
};

const prepareContractOptions = (contracts = []) => {
  return contracts
    .filter(contract => {
      return contract.number;
    })
    .map(contract => {
      return {
        value: parseInt(contract.number, 10),
        label: ` ${getLocation(contract.property)}, contrato: ${contract.number}`,
      };
    })
    .sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
};

/**
 * Certificate Register Form Component.
 */
function CertificateRegisterForm() {
  const navigate = useNavigate();
  const { setToast } = useContext(Toast.Context);

  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues,
  });

  const [incomeCertificateCreate] = useMutation(INCOME_CERTIFICATE_CREATE);

  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const {
    loading,
    error,
    data: contractsData,
  } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  const successToastMessage = {
    title: 'Gracias!',
    description: 'El certificado fue registrado con éxito.',
    backgroundColor: '#85bb25',
    icon: 'success',
  };

  const onSubmit = data => {
    return new Promise((resolve, reject) => {
      const documentPath = `income-certificates/${data.document[0].name}`;
      const contentType = data.document[0].type;

      Storage.put(documentPath, data.document[0], { contentType })
        .then(result => {
          const documentUrl = result.key;

          incomeCertificateCreate({
            variables: {
              identityId: auth.info.id,
              contract: data.contract,
              name: `Certificado #${data.contract}-${data.year}`,
              url: documentUrl,
              year: data.year,
              comment: data.comment,
            },
          })
            .then(response => {
              const { violations } = response.data.incomeCertificates;

              if (violations.length) {
                const { message } = violations[0];
                setError('server', null, message);
                return reject(message);
              }

              reset();
              setToast(successToastMessage);
              navigate('/virtual/arrendatario/certificados');
              return resolve(response);
            })
            .catch(errorResponse => {
              console.error(errorResponse.message);
              reject(errorResponse);
            });
        })
        .catch(err => {
          console.error(`No se ha podido subir el documento: ${err}`);
        });
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { contracts } = contractsData.clientByDocument;
  const contractOptions = prepareContractOptions(contracts);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {errors.server && (
        <Message variant='error' icon='md-error' html={errors.server.message} />
      )}

      <Field control={Input} label='Año' name='year' ref={register} disabled />

      <Field label='Inmueble asociado'>
        <Select name='contract' ref={register} placeholder='Contrato'>
          {contractOptions.map(option => {
            return (
              <option key={uuid()} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
      </Field>

      <Field
        control={Input}
        accept='application/pdf'
        required
        name='document'
        label='Documento'
        ref={register}
        type='file'
      />

      <Field
        control={TextArea}
        rows={5}
        name='comment'
        label='Observaciones'
        ref={register}
      />
      <Button
        type='submit'
        disabled={isSubmitting}
        loading={isSubmitting}
        sx={{ width: ['100%', 'auto'] }}
      >
        Registrar
      </Button>
    </Form>
  );
}

export default CertificateRegisterForm;
