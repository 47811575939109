import React from 'react';
import { Box } from 'theme-ui';

function WelcomePage() {
  return (
    <Box>
      <p>Bienvenido!</p>
    </Box>
  );
}

export default WelcomePage;
