import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

function DescriptionList({ items, variant, ...props }) {
  const baseStyles = {
    '& > div:last-child': {
      mb: 0,
    },
  };

  const itemStyles = {
    color: 'text-xweak',
    mb: 'xsmall',
    flex: '1',
  };

  const termStyles = {
    fontSize: '12px',
    flexShrink: 0,
    mr: 'xsmall',
  };

  const detailStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  return (
    <Box
      __css={baseStyles}
      variant={variant}
      __themeKey='description_list'
      {...props}
    >
      {items.map(item => (
        <Box
          key={uuid()}
          __css={itemStyles}
          variant={`${variant}.description`}
          __themeKey='description_list'
        >
          <Box __css={termStyles}>{item.term}</Box>
          <Box __css={detailStyles}>{item.details}</Box>
        </Box>
      ))}
    </Box>
  );
}

DescriptionList.defaultProps = {
  variant: 'default',
};

DescriptionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.node,
      details: PropTypes.node,
    })
  ).isRequired,
  variant: PropTypes.string,
};

export default DescriptionList;
