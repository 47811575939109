import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Icon, Nav, Modal } from 'ui';
import DropDown from 'components/DropDown';
import { AuthContext } from 'auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';
import ChangePassword from 'auth/forms/ChangePassword';

const GuestNav = ({ client, ...props }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await Auth.signOut();
      auth.clearUserData();
      navigate('/');
    } catch (error) {
      console.error(error.message);
    }
  };

  const [state, setState] = useState(false);
  const modalHandler = () => setState(true);
  const modalCloseHandler = () => {
    setState(false);
  };

  return (
    <>
      <Nav variant='fluid' {...props}>
        <Icon name='bell' mr='small' />
        <DropDown variant='navItem'>
          <DropDown.Item>
            <Box
              as='span'
              __css={{
                display: ['none', 'inline'],
                color: 'dark-4',
                fontWeight: '300',
                fontSize: '15px',
                width: '140px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {client.name}
            </Box>
            <Icon name='user' sx={{ ml: [null, 'small'] }} />
          </DropDown.Item>

          <DropDown.Item as='button' onClick={modalHandler}>
            <Icon name='mini-user' mr='small' />
            Cambiar contraseña
          </DropDown.Item>

          <DropDown.Divider />

          <DropDown.Item as='a' href='https://www.coninsa.co/ayudas' target='_blank'>
            <Icon name='life-ring' mr='small' />
            Ayudas
          </DropDown.Item>

          <DropDown.Divider />

          <DropDown.Item as='button' onClick={handleLogOut}>
            <Icon name='sign-out' mr='small' />
            Cerrar sesión
          </DropDown.Item>
        </DropDown>
      </Nav>

      <Modal
        sx={{ width: ['100%', '503px'] }}
        open={state}
        onClose={modalCloseHandler}
      >
        <Modal.Header
          bg='neutral-1'
          sx={{ color: 'white', textTransform: 'uppercase' }}
        >
          Cambiar contrasena
        </Modal.Header>
        <Modal.Content>
          <ChangePassword />
        </Modal.Content>
      </Modal>
    </>
  );
};

GuestNav.defaultProps = {
  client: [],
};

GuestNav.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
};

export default GuestNav;
