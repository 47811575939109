import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

function TabItem({ active, ...props }) {
  const baseStyles = {
    display: active ? 'block' : 'none',
    mb: 'medium',
    maxWidth: '992px',
    mx: 'auto',
    position: 'relative',
    pt: ['small', 'medium'],
  };

  return <Box sx={baseStyles} {...props} />;
}

TabItem.defaultProps = {
  active: false,
};

TabItem.propTypes = {
  active: PropTypes.bool,
};

export default TabItem;
