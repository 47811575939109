import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Collapse, Divider, Loading, Error } from 'ui';
import Layout from 'payment/components/Layout';
import Link from 'payment/components/Link';
import Heading from 'payment/components/Heading';
import { AuthContext } from 'auth/AuthContext';
import { CONTRACTS_QUERY } from 'payment/queries';
import PropertyDocuments from './PropertyDocuments';

import GeneralDocuments from './GeneralDocuments';
import S3Documents from './S3Documents';

function DeliveryProperty() {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { deliveredProperties } = data.clientByDocument;

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />
      <Divider />

      <Heading icon='certificates-round' label='Documentación de inmuebles' />

      <Collapse variant='greening'>
        {deliveredProperties.map(deliveredProperty => (
          <Collapse.Item
            title={`${deliveredProperty.id} ${deliveredProperty.name} ${deliveredProperty.projectType}`}
          >
            <GeneralDocuments />
            <PropertyDocuments propertyId={deliveredProperty.id} />
            <S3Documents id={deliveredProperty.projectId} name='generales' />
            <S3Documents
              id={deliveredProperty.projectId}
              name={deliveredProperty.machineName}
            />
          </Collapse.Item>
        ))}
      </Collapse>
    </Layout>
  );
}

export default DeliveryProperty;
