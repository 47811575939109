import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Heading } from 'theme-ui';
import { Button, Form, Field, Input, Text, Message as Error } from 'ui';

import HelpText from '../components/HelpText';
import { getErrorMessage } from '../cognito-utils';

function ForgotPasswordVerification() {
  const navigate = useNavigate();

  const {
    setError,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async data => {
    try {
      await Auth.forgotPasswordSubmit(
        data.email,
        data.verificationcode.toString(),
        data.newpassword
      );
      navigate('/contrasena-actualizada');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setError('server', null, errorMessage);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked autocomplete='off'>
      <Heading>Restablecer mi contraseña</Heading>

      <Text textSize='small' color='grey' mb='small'>
        Revise su correo electrónico para obtener el código de verificación.
      </Text>

      {errors.server && (
        <Error color='status-warning'>{errors.server.message}</Error>
      )}

      <Field
        control={Input}
        name='verificationcode'
        ref={register}
        placeholder='Código de verificación'
        error={errors.verificationcode}
        type='number'
        required
      />

      <Field
        control={Input}
        name='email'
        type='text'
        ref={register}
        placeholder='Correo electrónico'
        error={errors.email}
        required
      />

      <Field
        control={Input}
        name='newpassword'
        type='password'
        ref={register}
        placeholder='Nueva contraseña'
        error={errors.newpassword}
        required
      >
        <HelpText>
          Ingresa una combinación mínima de ocho caracteres, incluyendo al menos un
          número, letras en mayúscula y minúscula, y un carácter especial (como ! @ #
          % &).
        </HelpText>
      </Field>

      <Button fluid disabled={isSubmitting} loading={isSubmitting} type='submit'>
        Enviar
      </Button>
    </Form>
  );
}

export default ForgotPasswordVerification;
