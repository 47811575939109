import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Text } from 'theme-ui';

import { Loading, Error } from 'ui';

import IncomeCertificateCard from 'payment/blocks/IncomeCertificateCard';
import { INCOME_CERTIFICATE_QUERY } from 'payment/queries';

const IncomeCertificateList = ({ contracts }) => {
  const { loading, error, data } = useQuery(INCOME_CERTIFICATE_QUERY, {
    variables: { contracts },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const certificates = data.incomeCertificateQuery.entities;

  if (certificates.length === 0) {
    return <Text>No se encontró ningún certificado.</Text>;
  }

  return (
    <>
      {certificates.map(certificate => (
        <IncomeCertificateCard {...certificate} />
      ))}
    </>
  );
};

IncomeCertificateList.defaultProps = {
  contracts: [],
};

IncomeCertificateList.propTypes = {
  contracts: PropTypes.shape([]),
};

export default IncomeCertificateList;
