import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Heading } from 'theme-ui';

import { useStateWithSessionStorage } from 'libs/hooks';
import { Button, Form, Field, Input, Text } from 'ui';

function SignUpVerification() {
  const [defaultDocument] = useStateWithSessionStorage('document');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      document: defaultDocument,
    },
  });

  const onSubmit = async data => {
    try {
      await Auth.confirmSignUp(data.document, data.verificationcode.toString());
      navigate('/registro-exitoso');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      <Heading>Confirmar registro de usuario</Heading>

      <Text textSize='small' color='grey'>
        Le hemos enviado un correo con el código de verificación para confirmar su
        registro.
      </Text>

      <Field
        control={Input}
        name='document'
        type='text'
        ref={register}
        placeholder='Documento'
        error={errors.document}
        required
      />

      <Field
        control={Input}
        name='verificationcode'
        ref={register}
        placeholder='Código de verificación'
        error={errors.verificationcode}
        type='number'
        required
      />

      <Button fluid disabled={isSubmitting} loading={isSubmitting} type='submit'>
        Enviar
      </Button>
    </Form>
  );
}

export default SignUpVerification;
