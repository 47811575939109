import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Collapse, Divider, Icon, Modal } from 'ui';
import { Box } from 'theme-ui';
import { ToastContainer, toast } from 'react-toastify';

import ClientContext from 'modules/payment/ClientContext';
import Canon from 'payment/components/Canon';
import DescriptionList from 'payment/components/DescriptionList';
import ContractDocument from '../ContractCard/ContractDocuments';
import AccountStatement from './AccountStatementForm';
import CanonUrl from './LazyCanonButton';
import 'react-toastify/dist/ReactToastify.css';

const CONTRACT_LEASE_STATE = 'arrendado';

const PropertyCardDesktop = ({
  number,
  canon,
  endDate,
  monthsWarranty,
  payDay,
  rates,
  startDate,
  state,
  type,
  property,
}) => {
  const { client } = useContext(ClientContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const modalCloseHandler = () => setIsModalOpen(false);
  const notify = () => {
    toast(
      `Lo sentimos, en este momento tu contrato número ${number} no aplica para adelanto de canon.`,
      {
        type: toast.TYPE.WARNING,
        hideProgressBar: true,
      }
    );
  };

  return (
    <>
      <Card background='white'>
        <Card.Header>{property.type}</Card.Header>
        <Box
          __css={{
            display: ['block', 'grid'],
            gap: 'medium',
            gridTemplateColumns: [null, '1fr', null, null, 'auto 3fr 1fr'],
          }}
        >
          <Canon label='Canon de arrendamiento' price={canon} />
          <Card.Separator sx={{ mt: 'medium', display: [null, 'none'] }} />

          <DescriptionList
            variant='stacked'
            items={[
              { term: 'Contrato:', details: number },
              { term: 'Dirección:', details: property.address },
              { term: 'Estado:', details: state },
            ]}
          />

          {CONTRACT_LEASE_STATE === state && (
            <>
              <Card.Separator sx={{ display: [null, 'none'] }} />

              <Box
                __css={{
                  alignSelf: 'end',
                  textAlign: 'right',
                  mb: 'medium',
                }}
              >
                <CanonUrl
                  contract={number}
                  document={client.id}
                  mb='small'
                  notify={notify}
                />
                <ToastContainer />

                <Button round size='mediumSmall' onClick={openModal}>
                  <Icon name='document' mr='xsmall' />
                  Estado de cuenta
                </Button>
              </Box>
            </>
          )}
        </Box>

        <Card.Separator my='small' />

        <DescriptionList
          items={[
            { term: 'Tipo de contrato:', details: type },
            { term: 'Fecha de inicio:', details: startDate },
            { term: 'Fecha de vencimiento:', details: endDate },
            { term: 'Dia de pago:', details: payDay },
            { term: 'Garantia:', details: monthsWarranty },
            { term: 'Tarifa:', details: rates },
          ]}
          sx={{
            display: ['none', 'grid'],
            gap: 'small',
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          }}
        />

        {CONTRACT_LEASE_STATE === state && (
          <ContractDocument contract={number} sx={{ display: ['none', 'flex'] }} />
        )}

        <Collapse variant='mini' sx={{ display: [null, 'none'] }}>
          <Collapse.Item title='Ver más información'>
            <Divider />
            <DescriptionList
              items={[
                { term: 'Tipo de contrato:', details: type },
                { term: 'Fecha de inicio:', details: startDate },
                { term: 'Fecha de vencimiento:', details: endDate },
                { term: 'Dia de pago:', details: payDay },
                { term: 'Garantia:', details: monthsWarranty },
                { term: 'Tarifa:', details: rates },
              ]}
              sx={{ columns: 2 }}
            />

            {CONTRACT_LEASE_STATE === state && (
              <ContractDocument contract={number} />
            )}
          </Collapse.Item>
        </Collapse>
      </Card>

      <Modal
        sx={{ width: ['100%', '503px'], m: 'medium' }}
        open={isModalOpen}
        onClose={modalCloseHandler}
      >
        <Modal.Header
          bg='neutral-1'
          sx={{ color: 'white', textTransform: 'uppercase' }}
        >
          Consultar estado de cuenta
        </Modal.Header>
        <Modal.Content>
          <AccountStatement contract={number} />
        </Modal.Content>
      </Modal>
    </>
  );
};

PropertyCardDesktop.defaultProps = {
  number: '',
  canon: '',
  endDate: '',
  monthsWarranty: '',
  payDay: '',
  rates: '',
  startDate: '',
  state: '',
  type: '',
  property: {
    address: '',
    type: '',
  },
};

PropertyCardDesktop.propTypes = {
  number: PropTypes.string,
  canon: PropTypes.string,
  endDate: PropTypes.string,
  monthsWarranty: PropTypes.string,
  payDay: PropTypes.string,
  rates: PropTypes.string,
  startDate: PropTypes.string,
  state: PropTypes.string,
  type: PropTypes.string,
  property: PropTypes.shape({
    address: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default PropertyCardDesktop;
