import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

const Section = ({ children, ...props }) => (
  <Box
    __css={{ p: 'medium', pt: ['xlarge', '150px'], minHeight: '100vh' }}
    {...props}
  >
    <Box sx={{ m: '0 auto', maxWidth: '405px' }}>{children}</Box>
  </Box>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Section;
