import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Icon } from 'ui';

function TimelineItem({ complianceDate, commitmentDate, label, ...props }) {
  const containerStyles = {
    position: 'relative',
  };

  const circleStyles = {
    display: 'flex',
    position: 'absolute',
    top: 'calc(50% - 18px)',
    left: 'calc(50% - 18px)',
    height: '36px',
    width: '36px',
    bg: 'light-2',
    borderRadius: '36px',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const baseStyles = {
    bg: 'white',
    p: ['10px', 'small'],
    borderRadius: '5px',
    boxShadow: '0 3px 6px #00000029',
    width: ['45%', '46%'],
    position: 'relative',
    boxSizing: 'border-box',
  };

  const dateStyles = {
    display: ['block', 'flex'],
    fontSize: ['0.75rem', 1],
  };

  const labelStyles = {
    color: 'accent-1',
    fontWeight: 'heading',
    fontSize: ['0.68rem', '0.85rem'],
    mb: '.5rem',
  };

  return (
    <Box __css={containerStyles}>
      <Box __css={circleStyles}>
        {complianceDate ? (
          <Icon
            name='request'
            sx={{ color: 'status-ok', width: ['18px', '24px'] }}
          />
        ) : (
          <Icon
            name='error'
            sx={{
              color: '#AD8E8E',
              width: ['20px', '24px'],
            }}
          />
        )}
      </Box>
      <Box __css={baseStyles} {...props}>
        <Box __css={labelStyles}>{label}</Box>
        {complianceDate && (
          <Box __css={{ display: 'flex', mb: 'small', alignItems: 'center' }}>
            <Icon sx={{ mr: 'xsmall', fontSize: '16px' }} name='fa-calendar' />
            <Box __css={dateStyles}>
              <Box>Fecha de cumplimiento: </Box>
              {complianceDate}
            </Box>
          </Box>
        )}

        {!complianceDate && commitmentDate && (
          <Box __css={{ display: 'flex', mb: 'small', alignItems: 'center' }}>
            <Icon sx={{ mr: 'xsmall' }} name='fa-calendar' />
            <Box __css={dateStyles}>
              <Box>Fecha de compromiso: </Box>
              {commitmentDate}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

TimelineItem.propTypes = {
  complianceDate: PropTypes.string.isRequired,
  commitmentDate: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default TimelineItem;
