import React from 'react';

import { Private, Public } from 'routes/utils';

import EntryPage from 'auth/pages/EntryPage';
import SignInPage from 'auth/pages/SignInPage';
import SignUpPage from 'auth/pages/SignUpPage';
import SignUpVerificationPage from 'auth/pages/SignUpVerificationPage';
import ForgotPage from 'auth/pages/ForgotPage';
import SignDocumentPage from 'auth/pages/SignDocumentPage';
import CheckEmailPage from 'auth/pages/CheckEmailPage';
import ForgotVerificationPage from 'auth/pages/ForgotVerificationPage';
import ChangePasswordConfirmPage from 'auth/pages/ChangePasswordConfirmPage';
import ChangePasswordPage from 'auth/pages/ChangePasswordPage';
import ChangeTemporaryPasswordPage from 'auth/pages/ChangeTemporaryPasswordPage';
import Welcome from './pages/Welcome';
import SuccessfulRegister from './pages/SuccessfulRegister';

const routes = [
  { path: '', element: <Public render={EntryPage} /> },
  { path: 'iniciar-sesion', element: <Public render={SignInPage} /> },

  { path: 'registrar-usuario', element: <Public render={SignUpPage} /> },
  { path: 'verificar-usuario', element: <Public render={SignUpVerificationPage} /> },

  { path: 'recuperar-contrasena', element: <Public render={ForgotPage} /> },
  { path: 'validar-documento', element: <Public render={SignDocumentPage} /> },
  {
    path: 'numero-solicitud',
    element: <Public render={CheckEmailPage} />,
  },
  {
    path: 'verificar-contrasena',
    element: <Public render={ForgotVerificationPage} />,
  },
  {
    path: 'contrasena-actualizada',
    element: <Public render={ChangePasswordConfirmPage} />,
  },
  {
    path: 'cambiar-contrasena',
    element: <Private render={ChangePasswordPage} />,
  },
  {
    path: 'cambiar-contrasena-temporal',
    element: <Public render={ChangeTemporaryPasswordPage} />,
  },

  { path: 'bienvenido', element: <Welcome /> },
  { path: 'registro-exitoso', element: <SuccessfulRegister /> },
];

export default routes;
