import React from 'react';
import { responser } from 'libs/responser';

import { Nav } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import Section from 'auth/components/Section';
import AuthLanding from 'auth/components/AuthLanding';
import SignUp from 'auth/forms/SignUp';

const SignUpPageDesktop = () => (
  <>
    <Nav variant='floated'>
      <Nav.Link to='/'>Inicia sesión</Nav.Link>
    </Nav>

    <AuthLanding>
      <SignUp />
    </AuthLanding>
  </>
);

const SignUpPage = () => {
  return (
    <>
      <Nav variant='floated'>
        <Nav.Link to='/'>
          <CloseIcon />
        </Nav.Link>
      </Nav>

      <Section>
        <SignUp />
      </Section>
    </>
  );
};

export default responser(SignUpPage, SignUpPageDesktop);
