import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Text } from 'theme-ui';

import { Error, Loading, Collapse } from 'ui';
import { AuthContext } from 'auth/AuthContext';

import PropertyItem from '../components/PropertyItem';

import { PROPERTIES_BY_OWNER_QUERY } from '../queries';

function Page() {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const { loading, error, data } = useQuery(PROPERTIES_BY_OWNER_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const properties = data.propertiesByOwner;

  if (properties.length === 0) {
    return (
      <Text>
        Si tienes inmuebles en promoción con nosotros en este momento, aquí podrás
        conocer la gestión que nuestros Consultores han realizado y el interés que ha
        despertado tu inmueble entre los Clientes potenciales que visitan nuestro
        portal.
      </Text>
    );
  }

  return (
    <Collapse variant='whitening'>
      {properties.map(property => (
        <PropertyItem property={property} />
      ))}
    </Collapse>
  );
}

export default Page;
