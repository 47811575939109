import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const responser = (MobileComponent, DesktopComponent) => props => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <MobileComponent {...props} /> : <DesktopComponent {...props} />;
};

export default responser;
