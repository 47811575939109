import React from 'react';
import { responser } from 'libs/responser';

import { Nav } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import Section from 'auth/components/Section';
import AuthLanding from 'auth/components/AuthLanding';
import CheckUserDocument from 'auth/forms/CheckUserDocument';

const SignDocumentPageDesktop = () => (
  <>
    <Nav variant='floated'>
      <Nav.Link to='/'>Iniciar session</Nav.Link>
    </Nav>

    <AuthLanding>
      <CheckUserDocument />
    </AuthLanding>
  </>
);

const SignDocumentPage = () => {
  return (
    <>
      <Nav variant='floated'>
        <Nav.Link to='/'>
          <CloseIcon />
        </Nav.Link>
      </Nav>

      <Section>
        <CheckUserDocument />
      </Section>
    </>
  );
};

export default responser(SignDocumentPage, SignDocumentPageDesktop);
