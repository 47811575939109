import * as yup from 'yup';

export const t = str => {
  const translations = {
    number: 'numérico',
    document: 'Documento',
    'Incorrect username or password.': 'Su usuario o contraseña son incorrectos.',
    'You are already registered.': 'Ya estás registrado.',
    'Password attempts exceeded':
      'Ha excedido el número de intentos, vuelva a intentarlo mas tarde.',
    'The request did not approve the last document validation step.':
      'La solicitud no aprobó el último paso de validación del documento.',
    'The request is invalid.': 'El número de solicitud es inválido.',
    'The request did not approve the last request number validation step.':
      'La solicitud no aprobó el último paso de validación del número de solicitud.',

    'Password must contain a lower case letter.':
      'Debe contener una letra minúscula.',
    'Password must contain an upper case letter.':
      'Debe contener una letra mayúscula.',
    'Password must contain a special character.':
      'Debe contener un carácter especial, ej: (#, %, @, $,  &, !).',
    'Password must contain a number.': 'Debe contener un número.',
    'Password must contain at least 8 characters.':
      'Debe contener al menos 8 caracteres.',
  };

  return translations[str] ? translations[str] : str;
};

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio',
    notType: _ref => {
      return `Este campo debe ser de tipo '${t(_ref.type)}'`;
    },
  },

  string: {
    email: 'Este no es un correo válido',
  },
});

export default yup;
