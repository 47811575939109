import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button, Form, Field, Input, Message as Error } from 'ui';
import { checkDocument } from 'api';
import { useStateWithSessionStorage } from 'libs/hooks';
import yup, { t } from '../located-yup';

const SignupSchema = yup.object().shape({
  document: yup.number().required(),
});

function CheckUserDocument() {
  const navigate = useNavigate();

  const [, setDocument] = useStateWithSessionStorage('document');
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm({
    validationSchema: SignupSchema,
  });

  const onSubmit = async data => {
    setDocument(data.document);

    try {
      await checkDocument(data.document);
      navigate('/numero-solicitud');
    } catch (error) {
      if (error.status === 'fail') {
        navigate('/registrar-usuario');
      } else {
        setError('server', null, t(error.message));
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      {errors.server && <Error>{errors.server.message}</Error>}

      <p>Digita tu documento de identidad *</p>

      <Field
        control={Input}
        name='document'
        required
        ref={register}
        placeholder='Ingresa tu número'
        error={errors.document}
      />

      <Button fluid disabled={isSubmitting} loading={isSubmitting} type='submit'>
        Siguiente
      </Button>
    </Form>
  );
}

export default CheckUserDocument;
