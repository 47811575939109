import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Box, Heading } from 'theme-ui';

import { PRIVATE_ROOT_PAGE } from 'routes/utils';
import { Button, Form, Field, Input, Message } from 'ui';
import { AuthContext } from 'auth/AuthContext';

import { getErrorMessage } from '../cognito-utils';

function LogIn() {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm();

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = async ({ username, password }) => {
    try {
      const user = await Auth.signIn({ username, password });

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        auth.setUserData(false, user);

        navigate('/cambiar-contrasena-temporal');
      } else {
        const session = await Auth.userSession(user);
        const info = await Auth.currentUserInfo();

        auth.setUserData(true, user, session, info);

        navigate(PRIVATE_ROOT_PAGE);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setError('server', null, errorMessage);
    }
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit(onSubmit)} stacked>
        <Heading>Iniciar sesión</Heading>
        {errors.server && (
          <Message variant='error' icon='md-error'>
            {errors.server.message}
          </Message>
        )}
        <Field
          control={Input}
          name='username'
          ref={register}
          placeholder='¿Cuál es tu usuario?'
          error={errors.username}
          required
        />
        <Field
          control={Input}
          name='password'
          type='password'
          ref={register}
          placeholder='¿Cuál es tu contraseña?'
          error={errors.password}
          required
        />
        <Button
          fluid
          disabled={isSubmitting}
          loading={isSubmitting}
          type='submit'
          mb='small'
        >
          Iniciar sesión
        </Button>
      </Form>
      <Field>
        <Box as='a' color='accent-1' href='/recuperar-contrasena'>
          ¿Olvidaste tu contraseña?
        </Box>
      </Field>
    </Box>
  );
}

export default LogIn;
