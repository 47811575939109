import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from 'theme-ui';
import { Card, Divider } from 'ui';
import Link from 'payment/components/Link';
import Heading from 'payment/components/Heading';
import Layout from 'payment/components/Layout';

import ServiceRequest from '../forms/ServiceRequest';

const RequirementsRequest = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />

      <Divider />
      <Heading label='Solicitud de requerimientos' icon='requirements-round' />

      <Card background='white' sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ maxWidth: 800 }}>
          <ServiceRequest />
        </Box>
      </Card>
    </Layout>
  );
};

export default RequirementsRequest;
