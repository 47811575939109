import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Form } from 'ui';
import { Box, Label, Radio, Image, Text } from 'theme-ui';

import ClientContext from 'payment/ClientContext';
import Modal from 'ui/Modal';
import { CREATE_TRANSACTION } from 'payment/mutations';
import { HAS_PENDING_TRANSACTIONS_QUERY } from 'payment/queries';

import config from '../../../../config';

import ConfirmationModal from './ConfirmationModal';
import InvoiceModalDetails from './InvoiceModalDetails';
import PendingTransactionsModal from './PendingTransactionsModal';

import logoBancolombiaUrl from '../../images/logo-bancolombia.png';
import pseImageUrl from '../../images/pse-logo-2x.jpg';

export function normalizeEpaycoParams(data) {
  const nData = {
    name: data.name,
    description: data.description,
    invoice: data.paymentReference,
    currency: 'cop',
    amount: data.subtotal.replaceAll('.', ''),
    tax_base: '0',
    tax: '0',
    country: 'co',
    lang: 'es',

    // Onpage="false" - Standard="true"
    external: 'false',
    response: config.epayco.RESPONSE_URL,
    confirmation: config.epayco.CONFIRM_URL,

    // Atributos cliente
    methodsDisable: ['SP', 'CASH', 'DP'],
  };

  return nData;
}

const getProviderInfo = key => {
  const map = {
    wompi: {
      name: 'Wompi',
      message: `
        El monto máximo de pago para Persona Natural es de <strong>$10.000.000</strong>.
      `,
      confirmMessage: `A partir de este momento saldrás del sistema MiConinsa Virtual y
      continuarás en la Pasarela de Pagos de Wompi - Bancolombia. Recuerda
      que para realizar el pago en el Portal Web de Bancolombia, debes tener
      acceso a la clave dinámica. Si tienes dudas con tu clave dinámica,
      debes comunicarte directamente con Bancolombia. Si realizas el pago
      fuera del horario establecido por Bancolombia, éste se verá reflejado
      el día siguiente.`,
    },

    epayco: {
      name: 'Epayco',
      message: `
        El monto máximo de pago para Persona Natural es de <strong>$70.312.000</strong> y para Persona Jurídica es de <strong>$265.623.000</strong>.
      `,
      confirmMessage: `A partir de este momento saldrás del sistema MiConinsa Virtual y
      continuarás en la Pasarela de ePayco. Si realizas el pago fuera del
      horario establecido por tu Banco, éste se verá reflejado el día
      siguiente`,
    },
    // bancolombia: {
    //   name: 'Bancolombia',
    //   message: `
    //     El monto máximo de pago para Persona Natural o Persona Jurídica es de <strong>$5.000.000</strong>.
    //   `,
    // },
  };

  return map[key];
};

function InvoiceModal({ isOpen, closeHandler, invoice, date }) {
  const { client } = useContext(ClientContext);
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      provider: client.idType === 'NIT' ? 'epayco' : 'wompi',
    },
  });
  const [mutation, mutationInfo] = useMutation(CREATE_TRANSACTION);
  const provider = watch('provider');

  const { loading, data: queryData } = useQuery(HAS_PENDING_TRANSACTIONS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      paymentReference: invoice.paymentReference,
      document: client.id,
      timestamp: date.getTime().toString(),
    },
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const hasPendingTransactions =
    !loading && queryData?.hasPendingTransactions?.isTrue;

  const data = {
    document: client.id,

    documentType: client.idType,
    name: client.name,
    email: client.email,
    phone: client.phone,

    paymentReference: invoice.paymentReference,
    contract: invoice.contract,

    description: invoice.description,
    iva: invoice.iva,
    subtotal: invoice.paymentAmount,
    provider, // Get default provider.
  };

  const providerInfo = getProviderInfo(provider);

  const modalCloseHandler = isConfirmed => {
    const ePaycoHandler = window.ePayco.checkout.configure({
      key: config.epayco.PUBLIC_KEY,
      test: false,
    });

    if (isConfirmed) {
      return mutation({ variables: data })
        .then(({ data: mutationData }) => {
          const violations = mutationData?.createTransactionModel?.violations || [];

          // Check if the mutation has any violation before redirect to PSE payment page.
          if (!violations.length) {
            const redirecTo = mutationData?.createTransactionModel?.redirectTo;

            if (redirecTo !== 'no_redirect') {
              window.location = mutationData?.createTransactionModel?.redirectTo;
            } else {
              // Execute ePayco transaction.
              const epaycoData = normalizeEpaycoParams(data);
              ePaycoHandler.open(epaycoData);
            }
          }
        })
        .catch(errorResponse => {
          console.error(errorResponse.message);
        });
    }

    return closeHandler();
  };

  const pendingTransactionsModalCloseHandler = () => {
    closeHandler();
  };

  const onSubmit = () => openModal();

  const onlyNaturalPerson = data.documentType !== 'NIT';

  // Validate allowed providers.
  const isTrue = value => {
    if (value === 'epayco') {
      return true;
    }

    const totalIntValue = parseInt(invoice.paymentAmount.replaceAll('.', ''), 10);
    const bancolombiaMinimunAllowed = 10000000;

    if (totalIntValue < bancolombiaMinimunAllowed) {
      return true;
    }

    return 'El monto total a pagar excede el maximo de $10.000.000 aceptado por el servicio de Bancolombia.';
  };

  return (
    <>
      <Modal
        sx={{ width: ['100%', '700px'] }}
        open={isOpen}
        onClose={closeHandler}
        variant='fullScreen'
      >
        <Modal.Header
          bg='neutral-1'
          sx={{ color: 'white', textTransform: 'uppercase' }}
        >
          PAGOS EN LÍNEA CONINSA
        </Modal.Header>
        <Modal.Content>
          <InvoiceModalDetails data={data} />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box mb='medium'>
              <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'small' }}>
                Forma de pago:
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  mb: 'medium',
                  gap: '2rem',
                }}
              >
                {onlyNaturalPerson && (
                  <Label sx={{ display: 'flex', alignItems: 'center' }}>
                    <Radio
                      name='provider'
                      ref={register({
                        validate: isTrue,
                      })}
                      value='wompi'
                    />
                    <Image
                      src={logoBancolombiaUrl}
                      sx={{
                        height: 50,
                        ml: 'small',
                      }}
                    />
                  </Label>
                )}

                <Label sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    name='provider'
                    ref={register({
                      validate: isTrue,
                    })}
                    value='epayco'
                  />
                  <Image src={pseImageUrl} sx={{ height: 60, ml: 'small' }} />
                </Label>
              </Box>

              {errors?.provider && (
                <Text __css={{ color: 'red', fontSize: '14px' }}>
                  {errors?.provider?.message}
                </Text>
              )}
            </Box>

            <Box mb='small'>
              <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'xsmall' }}>
                Pago de factura a través de cuenta corriente o ahorros
              </Box>
              <Text __css={{ color: 'text-xweak', fontSize: '14px' }}>
                El pago de tu factura se efecturará utilizando los servicios de{' '}
                <strong>{providerInfo.name}</strong>, realizando el débito desde tu
                cuenta corriente o de ahorros.
              </Text>
            </Box>

            <Box mb='medium'>
              <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'xsmall' }}>
                Ten en cuenta:
              </Box>
              <Text
                __css={{ color: 'text-xweak', fontSize: '14px', minHeight: '3rem' }}
                dangerouslySetInnerHTML={{ __html: providerInfo.message }}
              />
            </Box>

            <Label sx={{ alignItems: 'center', mb: 'medium' }}>
              <Checkbox name='acceptConditions' ref={register} required />
              <Box
                as='a'
                sx={{ color: 'accent-1', fontSize: 1 }}
                href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                target='blank'
              >
                Acepto la Políticas de Tratamiento de Datos de Coninsa Ramón H. S.A.
              </Box>
            </Label>

            <Button
              sx={{ display: ['none', 'inherit'], mr: 'small' }}
              round
              type='submit'
              size='small'
              loading={loading}
            >
              Iniciar el proceso de pago
            </Button>

            <Button
              sx={{ display: ['inherit', 'none'] }}
              round
              type='submit'
              fluid
              loading={loading}
              disabled={loading}
            >
              Iniciar el proceso de pago
            </Button>
          </Form>
        </Modal.Content>
      </Modal>

      <ConfirmationModal
        isOpen={isModalOpen}
        mutation={mutationInfo}
        closeHandler={modalCloseHandler}
        message={providerInfo.confirmMessage}
      />

      {hasPendingTransactions && (
        <PendingTransactionsModal
          isOpen
          closeHandler={pendingTransactionsModalCloseHandler}
          message={queryData?.hasPendingTransactions?.message}
        />
      )}
    </>
  );
}

InvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    contract: PropTypes.string.isRequired,
    paymentReference: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string.isRequired,
    iva: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  date: PropTypes.shape({
    getTime: PropTypes.func.isRequired,
  }).isRequired,
};

export default InvoiceModal;
