import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Loading, Error } from 'ui';

import CertificateCard from 'payment/blocks/CertificateCard';
import { LAST_CERTIFICATE_QUERY } from 'payment/queries';

const LastCertificate = ({ document }) => {
  const { loading, error, data } = useQuery(LAST_CERTIFICATE_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const certificate = data.lastCertificateQuery;

  return certificate ? <CertificateCard {...certificate} /> : null;
};

LastCertificate.propTypes = {
  document: PropTypes.string.isRequired,
};

export default LastCertificate;
