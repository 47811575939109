import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Loading, Error } from 'ui';
import GeneralDocumentCard from './containers/GeneralDocumentCard';

import { S3_DOCUMENTS_QUERY } from './queries';

function PropertyDocuments({ id, name }) {
  const { loading, error, data } = useQuery(S3_DOCUMENTS_QUERY, {
    variables: { id, name },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const documents = data.documents || [];

  if (documents.length === 0) {
    return null;
  }
  return (
    <Box>
      {documents.map(doc => (
        <GeneralDocumentCard {...doc} />
      ))}
    </Box>
  );
}

PropertyDocuments.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PropertyDocuments;
