import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'auth/AuthContext';

export const PRIVATE_ROOT_PAGE = '/inicio';
export const PUBLIC_DEFAULT_PAGE = '/';

export const Private = ({ render: Component }) => {
  const auth = useContext(AuthContext);

  return auth.isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={PUBLIC_DEFAULT_PAGE} replace />
  );
};

Private.propTypes = {
  render: PropTypes.func.isRequired,
};

export const Public = ({ render: Component }) => {
  const auth = useContext(AuthContext);

  return !auth.isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={PRIVATE_ROOT_PAGE} replace />
  );
};

Public.propTypes = {
  render: PropTypes.func.isRequired,
};
