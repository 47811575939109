import React from 'react';
import PropTypes from 'prop-types';
import { InfoList } from 'ui';
import { Box } from 'theme-ui';

function InvoiceModal({ data }) {
  return (
    <>
      <Box>
        <InfoList>
          <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'small' }}>
            Tus datos personales
          </Box>
          <InfoList.Item title='Nombre:'>{data.name}</InfoList.Item>
          <InfoList.Item title='Cédula:'>{data.document}</InfoList.Item>
          <InfoList.Item title='Teléfono:'>{data.phone}</InfoList.Item>
          <InfoList.Item title='Correo electrónico:'>{data.email}</InfoList.Item>
        </InfoList>

        <InfoList>
          <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'small' }}>
            Infomación del pago de la factura
          </Box>
          <InfoList.Item title='Identificación del pago:'>
            {data.paymentReference}
          </InfoList.Item>
          <InfoList.Item title='Concepto del pago:'>{data.concept}</InfoList.Item>
          <InfoList.Item title='Total a pagar:'>{data.total}</InfoList.Item>

          {data?.details?.map(detail => (
            <InfoList.Item
              sx={{
                mb: 0,
                '& div:first-child': { pl: '0.75rem' },
                '& div:last-child': { fontWeight: 'normal' },
              }}
              title={`- ${detail.concept}:`}
            >
              {detail.formattedValue}
            </InfoList.Item>
          ))}
        </InfoList>
      </Box>
    </>
  );
}

InvoiceModal.defaultProps = {
  data: {
    description: '',
  },
};

InvoiceModal.propTypes = {
  data: PropTypes.shape({
    contract: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    paymentReference: PropTypes.string.isRequired,
    concept: PropTypes.string,
    total: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({})),
    subtotal: PropTypes.string,
  }),
};

export default InvoiceModal;
