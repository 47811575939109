import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Form } from 'ui';
import { Box, Label, Text } from 'theme-ui';

import ClientContext from 'payment/ClientContext';
import InvoiceModalDetailsTemp from './InvoiceModalDetailsTemp';

export function normalizeEpaycoParams(data) {
  const nData = {
    name: data.name,
    description: data.description,
    invoice: data.paymentReference,
    currency: 'cop',
    amount: data.subtotal.replaceAll('.', ''),
    tax_base: '0',
    tax: '0',
    country: 'co',
    lang: 'es',
  };

  return nData;
}

function InvoiceModal({ invoice, orderNumber }) {
  const { client } = useContext(ClientContext);
  const { register, handleSubmit } = useForm();

  const data = {
    document: client.id,

    documentType: client.idType,
    name: client.name,
    email: client.email,
    phone: client.phone,

    paymentReference: orderNumber,
    contract: invoice.contract,

    concept: invoice.concept,
    total: invoice.formattedTotal,
    details: invoice.details,
  };

  const onSubmit = () => {
    // Redirect to external page
    window.open('https://www.alianzaenlinea.com.co/recaudos/login', '_blank');
  };

  return (
    <Box>
      <InvoiceModalDetailsTemp data={data} />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label sx={{ alignItems: 'center', mb: 'medium' }}>
          <Checkbox name='acceptConditions' ref={register} required />
          <Box
            as='a'
            sx={{ color: 'accent-1', fontSize: 1 }}
            href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
            target='blank'
          >
            Acepto la Políticas de Tratamiento de Datos de Coninsa Ramón H. S.A.
          </Box>
        </Label>

        <Box mb='medium'>
          <Box __css={{ color: 'neutral-2', fontWeight: 'bold', mb: 'xsmall' }}>
            Ten en cuenta:
          </Box>
          <Text
            __css={{ color: 'text-xweak', fontSize: '14px', minHeight: '3rem' }}
          >
            Al dar clic en pagar saldrás de Mi Coninsa Virtual e iniciaras el proceso
            de pago en la Pasarela de Alianza en línea.
          </Text>
        </Box>

        <Button
          sx={{ display: ['none', 'inherit'], mr: 'small' }}
          round
          type='submit'
          size='small'
        >
          Pagar
        </Button>

        <Button sx={{ display: ['inherit', 'none'] }} round type='submit' fluid>
          Pagar
        </Button>
      </Form>
    </Box>
  );
}

InvoiceModal.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  invoice: PropTypes.shape({
    contract: PropTypes.string.isRequired,
    paymentReference: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string.isRequired,
    description: PropTypes.string,
    concept: PropTypes.string,
    total: PropTypes.string,
    formattedTotal: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default InvoiceModal;
