import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ui';
import { Box } from 'theme-ui';

const Canon = ({ label, price, ...props }) => {
  const baseStyles = {
    display: 'flex',
  };

  const labelStyles = {
    color: 'text-xweak',
    fontSize: 1,
    fontWeight: '300',
  };

  const priceStyles = {
    color: 'neutral-1',
    fontSize: '30px',
    fontWeight: 'bold',
    lineHeight: '1',
  };

  return (
    <Box __css={baseStyles} {...props}>
      <Icon name='document-finder' mr='small' />

      <Box>
        <Box __css={labelStyles}>{label}</Box>
        <Box __css={priceStyles}>$ {price}</Box>
      </Box>
    </Box>
  );
};

Canon.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default Canon;
