import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

function Price({ label, children, ...props }) {
  const labelStyles = {
    color: 'text-xweak',
    fontSize: 1,
    fontWeight: '300',
  };

  const childrenStyles = {
    color: 'neutral-1',
    fontSize: 3,
    fontWeight: 'bold',
    lineHeight: '1',
  };
  return (
    <Box {...props}>
      <Box __css={labelStyles}>{label}</Box>
      <Box __css={childrenStyles}>{children}</Box>
    </Box>
  );
}

Price.defaultProps = {
  label: undefined,
  children: undefined,
};

Price.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default Price;
