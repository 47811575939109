import React from 'react';
import { Box } from 'theme-ui';

const Table = props => {
  const tableStyles = {
    fontSize: ['13px', '16px'],
    mb: '3rem',
    width: '100%',
    borderCollapse: 'collapse',

    'tbody tr:nth-of-type(odd)': {
      bg: 'rgba(0,0,0,.05)',
    },
  };

  return <Box as='table' __css={tableStyles} {...props} />;
};

export const Th = props => {
  const thStyles = {
    fontSize: ['14px', '16px'],
    fontWeight: 'bold',
    p: ['20px', '10px'],
    verticalAlign: 'bottom',
    borderBottom: '1px solid #dee2e6',
    textAlign: 'left',
  };
  return <Box as='th' __css={thStyles} {...props} />;
};

export const Td = props => {
  const tdStyles = {
    fontWeight: '300',
    p: ['6px', '10px'],
    verticalAlign: 'top',
  };
  return <Box as='td' __css={tdStyles} {...props} />;
};

export default Table;
