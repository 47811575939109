import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Icon } from 'ui';

const TitleTag = ({ icon, title, ...props }) => {
  const containerStyles = {
    bg: 'neutral-2',
    color: 'white',
    display: 'flex',
    py: 'small',
    px: 'medium',
    alignItems: 'center',
  };

  const titleStyles = {
    fontFamily: 'heading',
    textTransform: 'uppercase',
  };

  return (
    <Box __css={containerStyles} {...props}>
      {icon && <Icon name={icon} ml='xsmall' mr='small' />}
      <Box __css={titleStyles}>{title}</Box>
    </Box>
  );
};

TitleTag.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default TitleTag;
