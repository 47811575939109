import { useEffect, useState } from 'react';
import axios from 'axios';
import Promise from 'promise';
import config from './config';

const api = axios.create({
  baseURL: config.drupal.API_URL,
});

const encodeFormData = data => {
  const body = new FormData();

  Object.keys(data).forEach(key => {
    body.append(key, data[key]);
  });

  return body;
};

const requestEnvelope = path => body => {
  return new Promise(function onPromise(resolve, reject) {
    api
      .post(path, body)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        const finalError = !error.response ? error : error.response.data;

        reject(finalError);
      });
  });
};

export const requestGetEnvelope = path => {
  return new Promise(function onPromise(resolve, reject) {
    api
      .get(path)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        const finalError = !error.response ? error : error.response.data;

        reject(finalError);
      });
  });
};

export const checkDocument = document => {
  const body = encodeFormData({ document });
  const request = requestEnvelope('/auth/user/check-document');

  return request(body);
};

export const checkEmail = (document, email) => {
  const body = encodeFormData({ document, email });
  const request = requestEnvelope('/auth/user/check-email');

  return request(body);
};

export const updateEmail = (document, email) => {
  const body = encodeFormData({ document, email });
  const request = requestEnvelope('/auth/user/update-email');

  return request(body);
};

// custom Hooks
export const useFetch = url => {
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await requestGetEnvelope(url);
      setData(response);
      setLoading(false);
    } catch ({ message }) {
      setLoading(false);
      setError(message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { loading, error, data };
};
