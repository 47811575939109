import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from 'theme-ui';

import { Collapse } from 'ui';

import ZohoCharts from '../containers/ZohoCharts';
import UniversalStatistics from '../containers/UniversalStatistics';
import Portal from '../containers/Portals';

const getSubtitle = property => {
  const { code, address, neighborhood, city, department } = property;
  const strings = [address, neighborhood, city, department];
  const complement = strings.filter(string => string).join(', ');

  return `(${code}) - ${complement}`;
};

function PropertyItem({ property, ...props }) {
  const { code, type, service, url } = property;
  const titleStyles = {
    fontFamily: 'Oswald',
    fontWeight: '400',
    mb: 'xsmall',
    color: 'dark-blue',
  };

  const subtitleStyles = {
    fontWeight: '300',
    fontSize: '14px',
  };

  const title = (
    <Box>
      <Box as='h3' __css={titleStyles}>
        {`${code} ${type} en ${service}`}
      </Box>
      <Box __css={subtitleStyles}>{getSubtitle(property)}</Box>
    </Box>
  );

  return (
    <Collapse.Item
      mb='medium'
      icons={['fi-plus-circle', 'fi-minus-circle']}
      title={title}
      key={code}
      {...props}
    >
      <Box pt='medium' px='medium'>
        <UniversalStatistics code={code}>
          {() => (
            <>
              <Portal code={code} />
              <Box as='h3'>Estadísticas de Inmueble</Box>
              <ZohoCharts code={code} />

              <Box as='h3'>
                {'Estadísticas Portal '}
                <Link
                  sx={{
                    color: 'accent-2',
                    fontWeight: '500',
                    textTransform: 'lowercase',
                  }}
                  href={`https://coninsa.co${url}`}
                  target='_blank'
                >
                  www.coninsa.co
                </Link>
              </Box>
            </>
          )}
        </UniversalStatistics>
      </Box>
    </Collapse.Item>
  );
}

PropertyItem.propTypes = {
  property: PropTypes.shape({
    code: PropTypes.string.isRequired,
    service: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default PropertyItem;
