import React from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import { Box } from 'theme-ui';

import saveAs from 'utils/saveAs';
import { Button, Card, Icon, Paragraph } from 'ui';

import TitleWithIcon from '../components/TitleWithIcon';

const IncomeCertificateCard = ({ contract, identityId, url, year }) => {
  const name = `Certificado de rentención ${year} del contrato ${contract}`;

  const generateDownloadUrl = async () => {
    const temporaryUrl = await Storage.get(url, { identityId });

    saveAs(temporaryUrl, name);
  };

  return (
    <Card>
      <Box
        sx={{
          display: ['block', 'flex'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box mb={['medium', 0]}>
          <TitleWithIcon icon='pdf' label={name} />
          <Paragraph color='text-xweak' mb={0}>
            Descarga la certificado del contrato de arrendamiento a tu nombre
          </Paragraph>
        </Box>

        <Button
          onClick={generateDownloadUrl}
          round
          size='mediumSmall'
          variant='secondary'
        >
          <Icon name='fa-download' mr='xsmall' />
          Descargar certificado
        </Button>
      </Box>
    </Card>
  );
};

IncomeCertificateCard.propTypes = {
  contract: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  identityId: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

export default IncomeCertificateCard;
