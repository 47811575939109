import React from 'react';
import { responser } from 'libs/responser';

import { Nav } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import Section from 'auth/components/Section';
import AuthLanding from 'auth/components/AuthLanding';
import CheckEmail from 'auth/forms/CheckEmail';

const CheckEmailPageDesktop = () => (
  <>
    <Nav variant='floated'>
      <Nav.Link to='/'>
        <CloseIcon />
      </Nav.Link>
    </Nav>

    <AuthLanding>
      <CheckEmail />
    </AuthLanding>
  </>
);

const CheckEmailPage = () => {
  return (
    <>
      <Nav variant='floated'>
        <Nav.Link to='/'>
          <CloseIcon />
        </Nav.Link>
      </Nav>

      <Section>
        <CheckEmail />
      </Section>
    </>
  );
};

export default responser(CheckEmailPage, CheckEmailPageDesktop);
