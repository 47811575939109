import React from 'react';
import PropTypes from 'prop-types';
import { Box, useThemeUI, Divider, Link as Anchor } from 'theme-ui';

import WelcomeHeading from 'auth/components/WelcomeHeading';
import bgBlue from 'assets/survey-image.jpg';

function Col(props) {
  const baseStyles = {
    display: 'flex',
    paddingTop: '8rem',
    paddingBottom: '1rem',
    flexDirection: 'column',
    px: 'medium',
    height: '100%',
  };

  return <Box __css={baseStyles} {...props} />;
}

function DynamicLanding({ children, phrase, title }) {
  const { theme } = useThemeUI();

  const baseStyles = {
    display: 'grid',
    minHeight: '100vh',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '40% 60%',
    gridTemplateAreas: '"left right"',
  };

  const leftGridStyles = {
    backgroundImage: `linear-gradient(#273475, rgba(25, 32, 78, 0.7)), url(${bgBlue})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    gridArea: 'left',
  };

  const leftColStyles = {
    justifyContent: 'space-between',
    ml: [null, null, null, 'medium', `calc((100vw - ${theme.sizes.xlarge}px) / 2)`],
    mr: '3rem',
    textAlign: 'left'
  };

  const rightColStyles = {
    mr: [null, null, null, 'medium', `calc((100vw - ${theme.sizes.xlarge}px) / 2)`],
    ml: '3rem',
  };

  return (
    <Box __css={baseStyles}>
      <Box __css={leftGridStyles} align='end'>
        <Col sx={leftColStyles}>
          <WelcomeHeading phrase={phrase}>{title}</WelcomeHeading>

          <Box mb='medium'>
            <Anchor
              href='https://www.coninsa.co/guia-para-usuarios-de-mi-coninsa-virtual'
              target='_blank'
              variant='light'
            >
              Aprende cómo usar Mi Coninsa Virtual
            </Anchor>
            <Divider width={100} />
            <Anchor
              href='https://www.coninsa.co/terminos-y-condiciones-de-uso-en-linea'
              target='_blank'
              variant='light'
            >
              Términos y condiciones de la plataforma
            </Anchor>
            <Divider width={100} />
            <Anchor
              href='https://www.coninsa.co/preguntas-frecuentes-mi-coninsa-virtual'
              target='_blank'
              variant='light'
            >
              ¿Necesitas ayuda?
            </Anchor>
          </Box>
        </Col>
      </Box>

      <Box
        __css={{
          gridArea: 'right',
          bg: 'light-1',
          boxShadow: '-5px 0px 6px #00000033',
        }}
      >
        <Col sx={rightColStyles}>{children}</Col>
      </Box>
    </Box>
  );
}

DynamicLanding.defaultProps = {
  phrase: undefined,
  title: undefined,
};

DynamicLanding.propTypes = {
  children: PropTypes.element.isRequired,
  phrase: PropTypes.node,
  title: PropTypes.node,
};

export default DynamicLanding;
