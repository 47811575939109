import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box } from 'theme-ui';

import { Button, Form, Field, Input } from 'ui';
import { useStateWithSessionStorage } from 'libs/hooks';

import { checkEmail } from 'api';
import { t } from '../located-yup';

function CheckEmail() {
  const navigate = useNavigate();

  const [document, setDocument] = useStateWithSessionStorage('document');

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async data => {
    try {
      await checkEmail(document, data.email);
      setDocument('');
      navigate('/bienvenido');
    } catch (error) {
      console.log({ error });
      if (error.status === 'fail') {
        setError('email', null, t(error.data.email));
      } else if (error.status === 'fail') {
        setError('server', null, t(error.message));
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      <p>Digita tu correo electrónico *</p>

      <Field
        control={Input}
        name='email'
        required
        ref={register}
        placeholder='Ingresa tu correo electrónico'
      />

      {errors?.email && (
        <Box sx={{ color: '#fe6762', fontSize: 1, mb: 'small' }}>
          Su correo electrónico no coincide con el registrado en nuestra base de
          datos por favor comuníquese con nuestro{' '}
          <Box
            as='a'
            sx={{ color: '#fe6762', textDecoration: 'underline' }}
            href='https://www.coninsa.co/servicio-cliente/'
            target='_blank'
          >
            centro de atención
          </Box>
        </Box>
      )}

      <Button fluid disabled={isSubmitting} loading={isSubmitting} type='submit'>
        Siguiente
      </Button>
    </Form>
  );
}

export default CheckEmail;
