import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Button, Card, Icon, Paragraph } from 'ui';

import TitleWithIcon from '../components/TitleWithIcon';

const CertificateCard = ({ document, url, year }) => {
  const regex = '//www.coninsa.co/ArchivosSincoERP/PDFS';
  const urlnew = url.replace(
    regex,
    'https://sincoerp.coninsaramonh.com/SINCOarchivosAdjuntos/SincoConinsaRH/ABR/formatosPDF'
  );

  return (
    <Card>
      <Box
        sx={{
          display: ['block', 'flex'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <TitleWithIcon
            icon='pdf'
            label={`Certificado de ingresos ${year} del documento nro. ${document}`}
          />
          <Paragraph color='text-xweak'>
            Este certificado incluye los ingresos del presente contrato, más los
            otros contratos en los cuales hayas sido afectado tributario.
          </Paragraph>
        </Box>

        <Button
          as='a'
          href={urlnew}
          target='_self'
          round
          size='mediumSmall'
          variant='secondary'
          sx={{ flexShrink: 0, ml: [0, 'medium'] }}
        >
          <Icon name='document' mr='xsmall' />
          Descargar certificado
        </Button>
      </Box>
    </Card>
  );
};

CertificateCard.propTypes = {
  document: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

export default CertificateCard;
