import React, { useContext } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';

import { Loading, Error } from 'ui';
import { AuthContext } from 'auth/AuthContext';
import Layout from 'payment/components/Layout';
import { CONTRACTS_QUERY } from 'payment/queries';
import PropertyWidget from '../components/PropertyWidget';

export const getImageFileName = name => {
  const publicPath = process.env.PUBLIC_URL;
  const projectAssetsPath = '/assets/projects/';
  const imageFileName = name.toLowerCase().replaceAll(' ', '-').concat('.jpeg');

  return publicPath + projectAssetsPath + imageFileName;
};

function PropertiesPage() {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');
  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { buyerGroups } = data.clientByDocument;

  const baseStyles = {
    columnGap: 'medium',
    display: 'grid',
    gridTemplateColumns: [
      'repeat(auto-fill, minmax(300px, 1fr))',
      'repeat(auto-fill, minmax(400px, 1fr))',
    ],
    rowGap: 'small',
  };

  return (
    <Layout>
      <Box __css={baseStyles}>
        {buyerGroups.map(buyerGroup => (
          <PropertyWidget
            as={LinkRouter}
            img={getImageFileName(buyerGroup?.mainName)}
            label={`Proyecto ${buyerGroup.mainName}`}
            to={buyerGroup.id}
          />
        ))}
      </Box>
    </Layout>
  );
}

export default PropertiesPage;
