import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Card, Divider } from 'ui';

import Canon from 'payment/components/Canon';
import DescriptionList from 'payment/components/DescriptionList';
import ContractDocument from './ContractDocuments';

const CONTRACT_LEASE_STATE = 'arrendado';

const ContractCard = ({
  canon,
  endDate,
  number,
  startDate,
  type,
  state,
  property,
}) => {
  return (
    <Card>
      <Card.Header>{`Contrato: ${number}`}</Card.Header>
      <Box
        __css={{
          columnGap: 'medium',
          display: 'grid',
          gridTemplateColumns: [null, '1fr', null, null, '1fr 2fr'],
          rowGap: ['xsmall', 'medium'],
        }}
      >
        <Canon label='Canon de arrendamiento' price={canon} />
        <Divider sx={{ display: ['block', 'none'] }} />
        <DescriptionList
          items={[
            { term: 'Tipo de contrato:', details: type },
            { term: 'Fecha de inicio:', details: startDate },
            { term: 'Fecha de vencimiento:', details: endDate },
            { term: 'Dirección del inmueble:', details: property.address },
          ]}
        />
      </Box>

      {CONTRACT_LEASE_STATE === state && <ContractDocument contract={number} />}
    </Card>
  );
};

ContractCard.defaultProps = {
  canon: '',
  endDate: '',
  startDate: '',
  number: '',
  type: '',
  state: '',
  property: {
    address: '',
  },
};

ContractCard.propTypes = {
  canon: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  number: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  property: PropTypes.shape({
    address: PropTypes.string,
  }),
};

export default ContractCard;
