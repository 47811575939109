import Modal from './Modal';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import ModalContext from './ModalContext';

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Context = ModalContext;

export default Modal;
