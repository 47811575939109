import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Icon } from 'ui';

function TabTrigger({ children, icon, img, active, ...props }) {
  return (
    <Box
      as='button'
      __css={{
        position: 'relative',
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: '4px',
        bg: active ? 'accent-1' : 'white',
        color: active ? 'white' : 'accent-1',
        display: 'flex',
        cursor: 'pointer',
        fontSize: 1,
        fontWeight: 'heading',
        px: ['1rem', 'large'],
        py: ['.6rem', 'small'],
        textTransform: 'uppercase',
        alignItems: 'center',
        flexShrink: 0,
      }}
      {...props}
    >
      {icon && <Icon name={icon} sx={{ mr: 'small', width: ['24px', '30px'] }} />}
      {img && (
        <Box
          as='img'
          src={img}
          sx={{ width: ['30px', '50px'], height: ['30px', '50px'], mr: 'small' }}
        />
      )}
      {children}
    </Box>
  );
}

TabTrigger.defaultProps = {
  active: false,
  children: undefined,
  icon: undefined,
  img: undefined,
};

TabTrigger.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  img: PropTypes.string,
  children: PropTypes.node,
};

export default TabTrigger;
