import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Price, Icon } from 'ui';
import Table, { Th, Td } from '../../statistics/components/Table';
import { formatPrice } from '../pages/InternalPropertyPage';

function PaymentPlanTable({ group }) {
  const iconStyles = {
    display: ['none', 'block'],
    fontSize: '32px',
    mr: '12px',
  };

  const paymentSummaryStyles = { display: 'flex', alignItems: 'center' };

  return (
    <>
      <Box
        sx={{
          display: ['grid', 'flex'],
          justifyContent: 'space-between',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
          py: ['small', 'large'],
        }}
      >
        <Box sx={paymentSummaryStyles}>
          <Icon name='ci-money-check' sx={iconStyles} />
          <Price label='Valor pagado:'>
            {formatPrice(group?.paymentSummary?.paidValue)}
          </Price>
        </Box>

        <Box sx={paymentSummaryStyles}>
          <Icon name='ci-money-check' sx={iconStyles} />
          <Price label='Valor por pagar:'>
            {formatPrice(group?.paymentSummary?.payableValue)}
          </Price>
        </Box>

        <Box sx={paymentSummaryStyles}>
          <Icon name='ci-money-bill' sx={iconStyles} />
          <Price label='Saldo en mora:'>
            {formatPrice(group?.paymentSummary?.totalBalance)}
          </Price>
        </Box>

        <Box sx={paymentSummaryStyles}>
          <Icon name='ci-circle-alert' sx={iconStyles} />
          <Price label='Días de mora:'>
            {group?.paymentSummary?.totalDaysArrears}
          </Price>
        </Box>
      </Box>

      <Box as='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ mb: 0 }}>
            <thead>
              <tr>
                <Th>Estado</Th>
                <Th>Concepto</Th>
                <Th>Fecha Pactada</Th>
                <Th>Valor Pactado</Th>
                <Th>Fecha de Pago</Th>
                <Th>Valor Pagado</Th>
                <Th>Saldo</Th>
                <Th>Días en Mora</Th>
              </tr>
            </thead>

            <tbody>
              {group?.paymentPlans.map(paymentPlan => (
                <tr key={paymentPlan.id}>
                  <Td>
                    {paymentPlan.isPaid && (
                      <Box
                        sx={{
                          bg: '#1bbc9c38',
                          borderRadius: '2px 13px 13px 2px',
                          color: '#087641',
                          fontSize: '10px',
                          px: '8px',
                          py: '2px',
                        }}
                      >
                        Pagado
                      </Box>
                    )}
                  </Td>
                  <Td>{paymentPlan?.concept}</Td>
                  <Td>{paymentPlan?.agreedDate}</Td>
                  <Td>{formatPrice(paymentPlan?.agreedValue)}</Td>
                  <Td>{paymentPlan?.lastPaymentDate}</Td>
                  <Td>{formatPrice(paymentPlan?.valuePaid)}</Td>
                  <Td>{formatPrice(paymentPlan?.balance)}</Td>
                  <Td>{paymentPlan?.daysArrears}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
}

PaymentPlanTable.propTypes = {
  group: PropTypes.shape({
    orderNumber: PropTypes.string,
    paymentPlans: PropTypes.shape([]),
    paymentSummary: PropTypes.shape(''),
  }).isRequired,
};

export default PaymentPlanTable;
