import React from 'react';
import { Box } from 'theme-ui';

function Timeline(props) {
  const baseStyles = {
    position: 'relative',
    maxWidth: '1024px',

    display: 'flex',
    flexDirection: 'column',
    gap: 'small',

    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: '2px',
      left: 'calc(50% - 1px)',
      bg: '#ddd',
      top: 0,
      bottom: 0,
    },

    '& > div:nth-of-type(even)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };

  return <Box sx={baseStyles} {...props} />;
}

export default Timeline;
