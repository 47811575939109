import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box } from 'theme-ui';
import { Loading, Error } from 'ui';
import { compose, pathOr, equals } from 'ramda';

import { UNIVERSAL_STATISTICS_QUERY } from '../queries';

const withoutStatistics = data => {
  const withoutZohoResults = compose(
    equals([]),
    pathOr([], ['statistics', 'zoho'])
  )(data);

  const withoutGoogleResults = compose(
    equals([]),
    pathOr([], ['statistics', 'google'])
  )(data);

  return withoutZohoResults && withoutGoogleResults;
};

function UniversalStatistics({ code, children }) {
  const { loading, error, data } = useQuery(UNIVERSAL_STATISTICS_QUERY, {
    variables: { code },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const isEmpty = withoutStatistics(data);

  if (isEmpty) {
    return (
      <Box sx={{ fontWeight: 300 }}>
        Lo sentimos, no se encontraron estadísticas para este inmueble.
      </Box>
    );
  }

  const { google, zoho } = data.statistics;

  return children({ google, zoho });
}

UniversalStatistics.propTypes = {
  code: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default UniversalStatistics;
