import React from 'react';
import PropTypes from 'prop-types';
import { Box, useThemeUI, Divider, Link as Anchor } from 'theme-ui';

import { Paragraph } from 'ui';

import WelcomeHeading from 'auth/components/WelcomeHeading';
import authBgImage from 'assets/auth-bg2x.png';

function Subtitle({ children }) {
  const subtitleStyles = {
    color: 'white',
    fontWeight: '500',
    mb: 'medium',
    textTransform: 'uppercase',
  };

  return (
    <Box as='h4' __css={subtitleStyles}>
      {children}
    </Box>
  );
}

function Col(props) {
  const baseStyles = {
    display: 'flex',
    paddingTop: '7.5rem',
    paddingBottom: '1rem',
    flexDirection: 'column',
    px: 'medium',
    height: '100%',
  };

  return <Box __css={baseStyles} {...props} />;
}

function AuthLanding({ children }) {
  const { theme } = useThemeUI();

  const baseStyles = {
    display: 'grid',
    minHeight: '100vh',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '60% 40%',
    gridTemplateAreas: '"left right"',
  };

  const leftGridStyles = {
    backgroundImage: `url(${authBgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    gridArea: 'left',
  };

  const leftColStyles = {
    justifyContent: 'space-between',
    ml: [null, null, null, 'medium', `calc((100vw - ${theme.sizes.xlarge}px) / 2)`],
    mr: '3rem',
  };

  const rightColStyles = {
    mr: [null, null, null, 'medium', `calc((100vw - ${theme.sizes.xlarge}px) / 2)`],
    ml: '3rem',
  };

  return (
    <Box __css={baseStyles}>
      <Box __css={leftGridStyles} align='end'>
        <Col sx={leftColStyles}>
          <div style={{ paddingTop: '15%', textAlign: 'left' }}>
            <WelcomeHeading phrase='Bienvenido a'>Mi Coninsa Virtual</WelcomeHeading>

            <Paragraph color='white' maxWidth='200'>
              Aquí podrás:
            </Paragraph>

            <Box>
              <Subtitle>Desarrollos inmobiliarios</Subtitle>
              <Box as='ul' style={{ color: 'white' }} mb='medium'>
                <li>Entrega de documentación de inmuebles.</li>
              </Box>
            </Box>

            <Box>
              <Subtitle>Arrendamientos</Subtitle>
              <Box as='ul' style={{ color: 'white' }} mb='medium'>
                <li>
                  Consultar tu estado de cuenta, historial de facturas y comunicados.
                </li>
                <li>Realizar pagos.</li>
                <li>Descargar tus certificados y constancias.</li>
                <li>
                  Hacer una solicitud de servicios públicos, certificado de
                  retenciones o terminación de contrato.
                </li>
                <li>Solicitar mantenimiento.</li>
                <li>Comunicarte con un Consultor.</li>
              </Box>
            </Box>

            <Box>
              <Paragraph color='white' maxWidth='200'>
                Además
              </Paragraph>
              <Box as='ul' style={{ color: 'white' }} mb='medium'>
                <Paragraph color='white' maxWidth='200'>
                  Arrendatarios y Coarrendatarios
                </Paragraph>
                <li>Consultar tu volante de recaudo y contratos asociados</li>
                <li>Solicitar un crédito.</li>
                <Divider width={100} />
                <Paragraph color='white' maxWidth='200'>
                  Propietarios y Apoderados
                </Paragraph>
                <li>
                  Consultar tus propiedades asociadas y Estadísticas de promoción.
                </li>
                <li>Adelantar un canon.</li>
              </Box>
            </Box>
          </div>
          <Box mb='medium'>
            <Anchor
              href='https://www.coninsa.co/guia-para-usuarios-de-mi-coninsa-virtual'
              target='_blank'
              variant='light'
            >
              Aprende cómo usar Mi Coninsa Virtual
            </Anchor>
            <Divider width={100} />
            <Anchor
              href='https://www.coninsa.co/terminos-y-condiciones-de-uso-en-linea'
              target='_blank'
              variant='light'
            >
              Términos y condiciones de la plataforma
            </Anchor>
            <Divider width={100} />
            <Anchor
              href='https://www.coninsa.co/preguntas-frecuentes-mi-coninsa-virtual'
              target='_blank'
              variant='light'
            >
              ¿Necesitas ayuda?
            </Anchor>
          </Box>
        </Col>
      </Box>

      <Box
        __css={{
          gridArea: 'right',
          bg: 'white',
          boxShadow: '-5px 0px 6px #00000033',
        }}
      >
        <Col sx={rightColStyles}>{children}</Col>
      </Box>
    </Box>
  );
}

AuthLanding.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLanding;
