import React from 'react';
import { Link } from 'react-router-dom';
import { responser } from 'libs/responser';
import { Box, Text } from 'theme-ui';

import { Nav } from 'ui';
import Section from 'auth/components/Section';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import AuthLanding from '../components/AuthLanding';

const WelcomeDesktop = () => (
  <AuthLanding>
    <Box>
      <h2>Registro exitoso!</h2>
      <Text mb='small'>
        Por favor revise su correo electrónico para continuar con el acceso a{' '}
        <strong>Mi Coninsa Virtual</strong>
      </Text>
      <Link to='/'>Regresar a la página inicial</Link>
    </Box>
  </AuthLanding>
);

const Welcome = () => {
  return (
    <>
      <Nav variant='floated'>
        <Nav.Link to='/iniciar-sesion'>
          <CloseIcon />
        </Nav.Link>
      </Nav>

      <Section>
        <h2>Registro exitoso!</h2>
        <Text mb='small'>
          Por favor revise su correo electrónico para continuar con el acceso a{' '}
          <strong>Mi Coninsa Virtual</strong>
        </Text>
        <Link to='/'>Regresar a la página inicial</Link>
      </Section>
    </>
  );
};

export default responser(Welcome, WelcomeDesktop);
