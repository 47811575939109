import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import axios from 'axios';
import { saveResponseAs } from 'utils/saveAs';

import { AuthContext } from 'auth/AuthContext';
import { Button, Card, Icon } from 'ui';
import TitleWithIcon from '../components/TitleWithIcon';

const ProofContractCard = ({ title, url }) => {
  const auth = useContext(AuthContext);

  const downloadProof = async () => {
    const token = auth.session?.getAccessToken().jwtToken;

    try {
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      saveResponseAs(response, title);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card>
      <Box
        sx={{
          display: ['block', 'flex'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TitleWithIcon icon='pdf' label={title} />

        <Button
          onClick={downloadProof}
          round
          size='mediumSmall'
          variant='secondary'
          sx={{ flexShrink: 0, ml: [0, 'xsmall'] }}
        >
          <Icon name='document' mr='xsmall' />
          Descargar constancia
        </Button>
      </Box>
    </Card>
  );
};

ProofContractCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ProofContractCard;
