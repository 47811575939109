import React from 'react';
import { responser } from 'libs/responser';
import { Link } from 'react-router-dom';
import { Box, Divider, Link as Anchor } from 'theme-ui';

import WelcomeHeading from 'auth/components/WelcomeHeading';
import Section from 'auth/components/Section';
import AuthLanding from 'auth/components/AuthLanding';
import LogIn from 'auth/forms/LogIn';
import mobileBgImage from 'assets/mobile-bg.png';

import { Nav, Button, Paragraph } from 'ui';
//import Alert from '../../maintenance/Alert';

const EntryPageDesktop = () => (
  <>
    
    <Nav variant='floated' brandTo='https://www.coninsa.co'>
      <Nav.Link to='validar-documento'>Regístrate</Nav.Link>
    </Nav>

    <AuthLanding>
      <LogIn />
    </AuthLanding>
  </>
);

const EntryPage = () => {
  return (
    <>
      
      <Nav variant='floated' brandTo='https://www.coninsa.co'>
        <Nav.Link to='/iniciar-sesion'>Inicia sesión</Nav.Link>
      </Nav>

      <Section
        sx={{
          backgroundImage: `url(${mobileBgImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Box>
          <WelcomeHeading phrase='Bienvenido a' mt='xlarge'>
            Mi Coninsa Virtual
          </WelcomeHeading>

          <Paragraph color='white'>
            Aquí podrás consultar tu estado de cuenta, realizar pagos y descargar tus
            certificados y constancias.
          </Paragraph>

          <Button as={Link} to='validar-documento' fluid color='accent-1'>
            Regístrate
          </Button>
        </Box>

        <Box mt='xlarge'>
          <Anchor
            href='https://www.coninsa.co/guia-para-usuarios-de-mi-coninsa-virtual'
            target='_blank'
            variant='light'
          >
            Aprende cómo usar Mi Coninsa Virtual
          </Anchor>
          <Divider width={100} />
          <Anchor
            href='https://www.coninsa.co/terminos-y-condiciones-de-uso-en-linea'
            target='_blank'
            variant='light'
          >
            Términos y condiciones de la plataforma
          </Anchor>
          <Divider width={100} />
          <Anchor
            href='https://www.coninsa.co/preguntas-frecuentes-mi-coninsa-virtual'
            target='_blank'
            variant='light'
          >
            ¿Necesitas ayuda?
          </Anchor>
        </Box>
      </Section>
    </>
  );
};

export default responser(EntryPage, EntryPageDesktop);
