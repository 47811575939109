import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box } from 'theme-ui';

import { AuthContext } from 'auth/AuthContext';
import GuestNav from 'auth/components/GuestNav';
import { Sidebar } from 'ui';

import { PRIVATE_ROOT_PAGE } from 'routes/utils';

const isSincoClient = user => {
  return user?.attributes?.['custom:is_sinco_client'] === '1';
};

function IndexPage() {
  const auth = useContext(AuthContext);
  const isTrue = isSincoClient(auth.user);

  if (isTrue) {
    return <Navigate to='/virtual' />;
  }

  const client = {
    name: auth.user.attributes.name,
  };

  return (
    <>
      <GuestNav client={client} brandTo='https://www.coninsa.co' />

      <Box __css={{ display: ['block', 'flex'], minHeight: '100vh' }}>
        <Sidebar
          sx={{ display: ['none', null, 'block'], width: '260px', flexShrink: 0 }}
        >
          <Sidebar.Link to={PRIVATE_ROOT_PAGE} title='Inicio' end />
        </Sidebar>

        <Box __css={{ bg: 'light-2', width: 'stretch', py: 'medium', px: 'large' }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default IndexPage;
