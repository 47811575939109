import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import Heading from 'payment/components/Heading';
import Layout from 'payment/components/Layout';

import { Divider, Loading, Error } from 'ui';
import Link from 'payment/components/Link';

import PropertyCard from 'payment/blocks/PropertyCard';
import { AuthContext } from 'auth/AuthContext';

import { CONTRACTS_QUERY } from 'payment/queries';

const Page = () => {
  const { role } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { contracts } = data.clientByDocument;

  const filteredContracts = contracts.filter(contract => {
    return contract.role === role;
  });
  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />
      <Divider />

      <Heading label='Propiedades asociadas' icon='document-round' />
      {filteredContracts.map(item => (
        <PropertyCard key={item.id} {...item} />
      ))}
    </Layout>
  );
};

export default Page;
