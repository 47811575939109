import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'theme-ui';

import Button from 'ui/Button';
import { getMonthName } from 'utils/date';

function AccountStatementCard({ url, month, year }) {
  const regex = '//www.coninsa.co/ArchivosSincoERP/PDFS';
  const urlnew = url.replace(
    regex,
    'https://sincoerp.coninsaramonh.com/SINCOarchivosAdjuntos/SincoConinsaRH/ABR/formatosPDF'
  );
  return (
    <Box
      __css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 'small',
      }}
    >
      <Text sx={{ fontWeight: '500' }}>
        {`Estado de cuenta de ${getMonthName(month)} del ${year}`}
      </Text>
      <Box>
        <Button as='a' href={urlnew} target='_blank' size='small'>
          Descargar
        </Button>
      </Box>
    </Box>
  );
}

AccountStatementCard.propTypes = {
  url: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

export default AccountStatementCard;
