import { gql } from '@apollo/client';

export const PROPERTY_DOCUMENTATION_QUERY = gql`
  query PropertyDocumentation($document: String!, $propertyId: String!) {
    propertyDocumentationQuery(
      filter: {
        conditions: [
          { field: "client", value: [$document] }
          { field: "code", value: [$propertyId] }
        ]
      }
    ) {
      entities {
        ... on PropertyDocumentation {
          code
          client
          documents: fieldDocuments {
            entity {
              ... on MediaPropertyDocument {
                name
                description: fieldDescription
                media: fieldMediaDocument {
                  document: entity {
                    ... on File {
                      fid
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GENERAL_DOCUMENTS_QUERY = gql`
  query GeneralDocuments {
    generalDocumentsQuery {
      name
      description
      file
      url
    }
  }
`;

export const S3_DOCUMENTS_QUERY = gql`
  query s3DocumentsQuery($id: String!, $name: String!) {
    documents: s3DocumentsQuery(id: $id, name: $name) {
      name
      url
    }
  }
`;
