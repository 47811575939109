import React, { Children, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import TabTrigger from './TabTrigger';

function Tabs({ children, ...props }) {
  const [activeIndex, setActiveIndex] = useState();

  const triggers = Children.map(children, (child, index) => {
    const { icon, img, label } = child.props;
    const isActive = activeIndex === index;

    const handleActiveClick = index => () => {
      if (activeIndex === index) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(index);
      }
    };

    return (
      <TabTrigger
        active={isActive}
        icon={icon}
        img={img}
        onClick={handleActiveClick(index)}
      >
        {label}
      </TabTrigger>
    );
  });

  const items = Children.map(children, (item, index) => {
    const isActive = activeIndex === index;

    return cloneElement(item, {
      active: isActive,
    });
  });

  const triggerStyles = {
    bg: 'white',
    display: 'flex',
    gap: ['1rem', 'medium'],
    justifyContent: ['start', 'center'],
    overflowX: 'auto',
    mb: 'medium',
  };

  return (
    <Box sx={{ position: 'relative', mb: 'medium' }} {...props}>
      <Box sx={triggerStyles}>{triggers}</Box>

      {items}
    </Box>
  );
}

Tabs.defaultProps = {
  children: undefined,
};

Tabs.propTypes = {
  children: PropTypes.node,
};

export default Tabs;
