import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui';
import { Box, Text } from 'theme-ui';

import Modal from 'ui/Modal';

function ConfirmationModal({ isOpen, closeHandler, mutation, message }) {
  const { loading, data } = mutation;
  const violations = data?.createTransactionModel?.violations || [];
  const hasViolations = violations.length > 0;

  const onClickCloseButton = () => {
    const isconfirmed = !hasViolations;
    return closeHandler(isconfirmed);
  };

  return (
    <Modal
      sx={{ width: ['100%', '600px'], mx: ['medium', 0] }}
      open={isOpen}
      onClose={closeHandler}
    >
      <Modal.Header
        bg='status-alert'
        sx={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        ¡Ten en cuenta!
      </Modal.Header>
      <Modal.Content providerInfo='pse'>
        <Box mb='medium'>
          {loading && <Text>Procesando ...</Text>}

          {!loading && !hasViolations && <Text color='text-xweak'>{message}</Text>}

          {hasViolations && <Text>{violations[0].message}</Text>}
        </Box>

        <Button
          sx={{ display: ['none', 'inherit'], mr: 'small' }}
          round
          type='submit'
          size='small'
          loading={loading}
          onClick={onClickCloseButton}
        >
          Aceptar
        </Button>

        <Button
          sx={{ display: ['inherit', 'none'], mr: 'small' }}
          round
          type='submit'
          fluid
          loading={loading}
          onClick={onClickCloseButton}
        >
          Aceptar
        </Button>
      </Modal.Content>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  mutation: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      createTransactionModel: PropTypes.shape({
        violations: PropTypes.shape([]),
      }),
    }),
  }).isRequired,
  message: PropTypes.string.isRequired,
};

export default ConfirmationModal;
