import * as yup from 'yup';
import PasswordValidator from 'password-validator';

// helper for yup transform function
export const emptyStringToNull = (value, originalValue) => {
  return typeof originalValue === 'string' && originalValue === '' ? null : value;
};

const email = yup
  .string()
  .email('Su correo electrónico no es válido')
  .required('Su correo electrónico es obligatorio');

// Phone with mask number: +57 (456) 556-6555
const phoneRegExp = /^\+?\d{2}[ ]\(?\d{3}\)[ ]?\d{3}-?\d{4}$/;

const phone = yup
  .string()
  .required('Su número telefónico es obligatorio')
  .matches(phoneRegExp, 'Su número telefónico no es válido');

// @ALERT: use this validator to default React Hook forms.
const password = {
  hasLowercase: value =>
    new PasswordValidator().has().lowercase().validate(value) ||
    'Password must contain a lower case letter.',
  hasUppercase: value =>
    new PasswordValidator().has().uppercase().validate(value) ||
    'Password must contain an upper case letter.',
  hasDigits: value =>
    new PasswordValidator().has().digits().validate(value) ||
    'Password must contain a number.',
  minLength: value =>
    new PasswordValidator().is().min(8).validate(value) ||
    'Password must contain at least 8 characters.',
};

const validations = {
  phone,
  email,
  password,
};

export default validations;
