import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import Icon from 'ui/Icon';

const Summary = ({ icon, label, quantity, bgColor }) => {
  const baseStyles = {
    display: 'flex',
    bg: 'white',
    p: '15px',
    mb: '25px',
  };

  const labelStyles = {
    color: '#494949',
    fontSize: '13px',
    fontWeight: 'bold',
  };

  const amountStyles = {
    fontSize: '25px',
    fontWeight: '300',
    color: '#a3aaab',
  };

  const circleIconStyles = {
    bg: bgColor,
    borderRadius: '30px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
    height: '56px',
    width: '56px',
  };

  return (
    <Box __css={baseStyles}>
      <Box mr='small'>
        <Box __css={circleIconStyles}>
          <Icon name={`fa-${icon}`} />
        </Box>
      </Box>

      <div>
        <Box __css={labelStyles}>{label}</Box>
        <Box __css={amountStyles}>{quantity}</Box>
      </div>
    </Box>
  );
};

Summary.defaultProps = {
  bgColor: 'accent-2',
};

Summary.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
};

export default Summary;
