import React from 'react';
import { Box, Text } from 'theme-ui';

import saveAs from 'utils/saveAs';
import { Button, Card, Icon } from 'ui';
import TitleWithIcon from '../../../components/TitleWithIcon';

const mapDocumentProps = props => {
  return {
    name: props.entity.name,
    description: props.entity.description,
    url: props.entity.media.document.url,
  };
};

function DocumentCard(props) {
  const { name, description, url } = mapDocumentProps(props);

  return (
    <Card
      sx={{
        display: ['block', 'flex'],
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <TitleWithIcon mb={description ? 'small' : 0} icon='pdf' label={name} />
        {description && <Text>{description}</Text>}
      </Box>

      <Box sx={{ flexShrink: 0 }}>
        <Button
          onClick={() => saveAs(url, name)}
          round
          size='mediumSmall'
          variant='secondary'
        >
          <Icon name='fa-download' mr='xsmall' />
          Descargar
        </Button>
      </Box>
    </Card>
  );
}

export default DocumentCard;
