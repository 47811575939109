import { from, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

import config from './config';

const FORBIDDEN_ERROR_CODE = 403;

// cached storage for the user token
let token;

const withToken = setContext(() => {
  // if you have a cached value, return it immediately
  if (token) return { token };

  return Auth.currentSession()
    .then(session => ({ token: session?.getAccessToken().jwtToken }))
    .catch(error => {
      console.error('Auth Error:', error);
    });
});

// eslint-disable-next-line consistent-return
const resetToken = onError(({ networkError, operation, forward }) => {
  if (
    networkError &&
    networkError.name === 'ServerParseError' &&
    networkError.statusCode === FORBIDDEN_ERROR_CODE
  ) {
    // remove cached token on 403 from the server
    token = null;
    return forward(operation);
  }
});

const setAuthorizationLink = setContext((_, previousContext) => {
  const headers = {};

  // Prevent undefined authtorization header sending to the API server.
  if (previousContext.token) {
    headers.authorization = `Bearer ${previousContext.token}`;
  }

  return {
    headers,
  };
});

const asyncAuthLink = resetToken.concat(withToken);
const httpLink = new HttpLink({ uri: config.drupal.GRAPHQL_URL });

const clientFactory = new ApolloClient({
  link: from([asyncAuthLink, setAuthorizationLink, httpLink]),
  cache: new InMemoryCache(),
});

export default clientFactory;
