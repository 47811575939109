import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { Box, Select, Text } from 'theme-ui';
import moment from 'moment';
import 'moment/locale/es';

import { AuthContext } from 'auth/AuthContext';
import { Loading, Error, Divider } from 'ui';
import Form from 'ui/Form';
import Field from 'ui/Field';
import Button from 'ui/Button';

import { capitalizeFirstLetter } from 'utils/string';

import Card from './AccountStatementCard';
import { ACCOUNT_STATEMENT_QUERY } from '../../queries';

moment.locale('es');

const getMothsOptions = () => {
  const indexes = moment().date() >= 10 ? [0, 1, 2] : [1, 2, 3];
  const months = indexes.map(index => {
    const date = moment();
    const lastMonth = date.subtract(index, 'months');

    return {
      label: capitalizeFirstLetter(lastMonth.format('MMMM [de] YYYY')),
      value: lastMonth.format('Y|M'),
    };
  });
  return months;
};

function AccountStatementForm({ contract }) {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isSubmitted },
  } = useForm();

  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');

  const [getData, { loading, error, data }] = useLazyQuery(ACCOUNT_STATEMENT_QUERY);

  const onSubmit = async ({ yearMonth }) => {
    const date = yearMonth.split('|');
    const year = date[0];
    const month = date[1];

    await getData({ variables: { contract, document, month, year } });
  };

  const months = getMothsOptions();
  const accountStatements = data?.accountStatementQuery || [];

  return (
    <>
      <Text as='p' mb='medium'>
        El estado de cuenta para el periodo actual, estará disponible a partir del
        séptimo día del mes.
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {errors.server && <Error>{errors.server.message}</Error>}

        <Field label='Selecciona un período'>
          <Select name='yearMonth' ref={register} placeholder='Mes'>
            {months.map(month => {
              return <option value={month.value}>{month.label}</option>;
            })}
          </Select>
        </Field>

        <Button
          type='submit'
          disabled={isSubmitting}
          loading={isSubmitting}
          sx={{ width: ['100%', 'auto'] }}
        >
          Consultar
        </Button>
      </Form>

      <Box>
        {isSubmitted && <Divider />}
        {loading && <Loading />}
        {error && <Error />}

        {!loading && isSubmitted && !accountStatements.length && (
          <Text __css={{ fontWeight: 300, textAlign: 'center', p: 'medium' }}>
            Lo sentimos, no se encontró ningún documento.
          </Text>
        )}

        {!loading &&
          accountStatements.map(accountStatement => {
            return <Card {...accountStatement} />;
          })}
      </Box>
    </>
  );
}

AccountStatementForm.propTypes = {
  contract: PropTypes.string.isRequired,
};

export default AccountStatementForm;
