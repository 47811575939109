import React from 'react';
import { useQuery } from '@apollo/client';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Box } from 'theme-ui';

import { Error, Loading } from 'ui';
import Link from 'payment/components/Link';

import { DOCUMENT_QUERY } from '../../queries';

const getIcon = type => {
  const defaultIcon = 'contract';

  const map = {
    1: 'contract',
    2: 'inventory',
    3: 'request',
  };

  return map[type] || defaultIcon;
};

function ContractDocument({ contract, ...props }) {
  const { role } = useParams();
  const { loading, error, data } = useQuery(DOCUMENT_QUERY, {
    variables: { contract, role },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const documents = data.documentQuery;

  const baseStyles = {
    position: 'relative',
    display: ['block', 'flex'],
    justifyContent: 'center',
    pt: 'medium',
    borderTop: '1px solid',
    borderTopColor: 'light-3',
  };

  const documentLinkStyles = {
    fontSize: 1,
    flexDirection: ['row', 'column'],
    textAlign: ['left', 'center'],
    width: [null, '140px'],

    position: 'relative',
    mx: [null, 'small'],
    borderBottom: ['1px solid', 0],
    borderBottomColor: 'light-3',

    pb: ['medium', 0],

    svg: {
      mb: 'xsmall',
      flexShrink: 0,
      width: 30,
    },

    '&::after': {
      content: [null, '""'],
      right: -12,
      position: 'absolute',
      height: '50px',
      borderLeft: '1px solid',
      borderLeftColor: 'light-3',
      ml: 'medium',
    },

    '&:last-child': {
      borderBottom: 0,
      mb: 0,
      pb: 0,
    },

    '&:last-child::after': {
      borderLeft: '0',
    },
  };

  if (documents.length === 0) {
    return null;
  }

  return (
    <Box __css={baseStyles} {...props}>
      {documents.map(document => {
        return (
          <Link
            sx={documentLinkStyles}
            label={document.typeDetail}
            icon={getIcon(document.type)}
            href={document.url}
            target='_blank'
            key={uuid()}
          />
        );
      })}
    </Box>
  );
}

ContractDocument.propTypes = {
  contract: PropTypes.string.isRequired,
};

export default ContractDocument;
