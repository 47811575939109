import React from 'react';

import { Private } from 'routes/utils';

import IndexPage from './pages';
import WelcomePage from './pages/welcome';

const routes = [
  {
    path: 'inicio',
    element: <Private render={IndexPage} />,
    children: [{ path: '', element: <WelcomePage /> }],
  },
];

export default routes;
