import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Loading, Error } from 'ui';

import InvoiceCard from 'payment/blocks/InvoiceCard';
import { INVOICE_QUERY } from 'payment/queries';

const Invoices = ({ contract, role }) => {
  const { loading, error, data } = useQuery(INVOICE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { contract, role },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const invoices = data.invoiceQuery;

  return (
    <>
      {invoices.map(invoice => (
        <InvoiceCard key={uuid()} {...invoice} />
      ))}
    </>
  );
};

Invoices.propTypes = {
  contract: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default Invoices;
