import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon } from 'ui';
import { Box } from 'theme-ui';

import Canon from 'payment/components/Canon';
import DescriptionList from 'payment/components/DescriptionList';
import Link from 'payment/components/Link';
import InvoiceModal from './InvoiceModal';
import BankClosureModal from './BankClosureModal';

export const getCurrentTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const currentTime =
    Math.round((hours + minutes / 60 + seconds / 3600) * 100000) / 100000;

  return currentTime;
};

export const isBanckClosureTime = () => {
  const minRange = 17.41667;
  const maxRange = 17.58306;
  const currentTime = getCurrentTime();

  if (minRange <= currentTime && maxRange >= currentTime) {
    return true;
  }

  return false;
};

function InvoiceCard(props) {
  const { billUrl, contract, chainFlyerUrl, paymentAmount, propertyAddress } = props;
  const regex = '//www.coninsa.co/ArchivosSincoERP/PDFS';
  const billUrlnew = billUrl.replace(
    regex,
    'https://sincoerp.coninsaramonh.com/SINCOarchivosAdjuntos/SincoConinsaRH/ABR/formatosPDF'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const modalCloseHandler = () => setIsModalOpen(false);

  return (
    <>
      <Card background='white'>
        <Card.Header>
          Tu factura del mes
          <Link
            label='Descargar factura en pdf'
            icon='download'
            href={chainFlyerUrl}
            target='_blank'
            variant='reverse'
            sx={{ display: ['none', 'inherit'] }}
          />
        </Card.Header>

        <Box
          __css={{
            display: ['block', 'grid'],
            columnGap: 'medium',
            gridTemplateColumns: [null, '1fr 1fr', null, null, 'auto 2fr 2fr'],
            rowGap: 'small',
          }}
        >
          <Canon label='Canon de arrendamiento' price={paymentAmount} />
          <Card.Separator sx={{ mt: 'medium', display: [null, 'none'] }} />

          <DescriptionList
            variant='stacked'
            items={[
              { term: 'Contrato:', details: contract },
              { term: 'Dirección:', details: propertyAddress },
              { term: 'Estado:', details: 'Arrendado' },
            ]}
          />

          <Box sx={{ display: [null, 'none'] }}>
            <Card.Separator sx={{ mt: 'medium' }} />

            <Link
              label='Descargar factura en pdf'
              icon='download'
              href={chainFlyerUrl}
              target='_blank'
              variant='reverse'
              sx={{ justifyContent: 'center' }}
            />

            <Card.Separator sx={{ mt: 'medium' }} />
          </Box>

          <Box
            __css={{
              alignSelf: 'end',
              textAlign: 'right',
              gridColumnStart: [null, 1, null, null, 'initial'],
              gridColumnEnd: [null, 3, null, null, 'initial'],
            }}
          >
            <Button
              as='a'
              href={billUrlnew}
              target='_blank'
              round
              size='mediumSmall'
              variant='secondary'
            >
              <Icon name='document' mr='xsmall' />
              Volante de recaudo
            </Button>

            <Button
              round
              size='mediumSmall'
              onClick={openModal}
              sx={{ mt: 'small', ml: [0, 'small'] }}
            >
              Pagar factura
              <Icon name='arrow-right' ml='small' />
            </Button>
          </Box>
        </Box>
      </Card>

      {isBanckClosureTime() ? (
        <BankClosureModal isOpen={isModalOpen} closeHandler={modalCloseHandler} />
      ) : (
        isModalOpen && (
          <InvoiceModal
            isOpen={isModalOpen}
            closeHandler={modalCloseHandler}
            invoice={props}
            date={new Date()}
          />
        )
      )}
    </>
  );
}

InvoiceCard.propTypes = {
  contract: PropTypes.string.isRequired,
  paymentAmount: PropTypes.string.isRequired,
  propertyAddress: PropTypes.string.isRequired,
  billUrl: PropTypes.string.isRequired,
  chainFlyerUrl: PropTypes.string.isRequired,
};

export default InvoiceCard;
