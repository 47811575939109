import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { createStore, StateMachineProvider } from 'little-state-machine';

import { Nav, Loading } from 'ui';
// import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { responser } from 'libs/responser';

import coninsaWhiteIcon from 'assets/icons/coninsa-white.svg';

import Section from 'auth/components/Section';
import SurveyLanding from '../components/SurveyLanding';

createStore({
  ownerSurvey: {},
  ownerSurveyAccess: {},
});

const SurveyDesktop = () => (
  <StateMachineProvider>
    <Nav variant='floated' brandIcon={coninsaWhiteIcon}>
      {/* <Nav.Link to='/iniciar-sesion'>Atras</Nav.Link> */}
    </Nav>

    <SurveyLanding>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </SurveyLanding>
  </StateMachineProvider>
);

const Survey = () => {
  return (
    <StateMachineProvider>
      <Nav variant='floated'>
        {/* <Nav.Link to='/'>
          <CloseIcon />
        </Nav.Link> */}
      </Nav>

      <Section>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </Section>
    </StateMachineProvider>
  );
};

export default responser(Survey, SurveyDesktop);
