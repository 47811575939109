import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui';
import { Box, Text } from 'theme-ui';

import Modal from 'ui/Modal';

function BankClosoreModal({ isOpen, closeHandler }) {
  return (
    <Modal
      sx={{ width: ['100%', '700px'], mx: ['medium', 0] }}
      open={isOpen}
      onClose={closeHandler}
    >
      <Modal.Header
        bg='status-alert'
        sx={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        ¡Ten en cuenta!
      </Modal.Header>
      <Modal.Content>
        <Box mb='medium'>
          <Text __css={{ color: 'text-xweak' }}>
            En este momento nos encontramos en horario de cierre Bancario. Realiza tu
            pago a partir de las <strong>5:35 pm</strong>. Quedará registrado con
            fecha del siguiente día hábil.
          </Text>
        </Box>

        <Button
          sx={{ display: ['none', 'inherit'], mr: 'small' }}
          round
          type='submit'
          size='small'
        >
          Aceptar
        </Button>

        <Button
          sx={{ display: ['inherit', 'none'], mr: 'small' }}
          round
          type='submit'
          fluid
        >
          Aceptar
        </Button>
      </Modal.Content>
    </Modal>
  );
}

BankClosoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default BankClosoreModal;
