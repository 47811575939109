import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import { ApolloProvider } from '@apollo/client';
import { AuthContext } from 'auth/AuthContext';

import { ThemeProvider } from 'theme-ui';
import Toast from 'ui/Toast';

import theme, { GlobalStyle } from './theme/globalStyle';
import clientFactory from './apollo-client';
import AppRoutes from './routes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      session: null,
      info: null,
    };
  }

  async componentDidMount() {
    Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signIn') {
        const user = await Auth.currentAuthenticatedUser();
        this.setUserData(true, user);
      }
    });

    try {
      const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const info = await Auth.currentUserInfo();

      this.setUserData(true, user, session, info);
    } catch (error) {
      if (error !== 'No current user') {
        console.error(error);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  getUserAttribute = user => attribute => {
    const attributes = user.attributes
      ? user.attributes
      : user.challengeParam.userAttributes;

    const keyMap = {
      document: 'custom:document',
    };

    const key = keyMap[attribute];

    return attributes[key];
  };

  setUserData = (isAuthenticated, user, session, info = {}) => {
    this.setState({
      isAuthenticated,
      user,
      session,
      info,
    });
  };

  clearUserData = () => {
    this.setState({
      isAuthenticated: false,
      user: null,
      session: null,
      info: null,
    });
  };

  render() {
    const { isAuthenticated, isAuthenticating, user, session, info } = this.state;
    const authProps = {
      isAuthenticated,
      user,
      session,
      info,

      setAuthStatus: this.setAuthStatus,
      setUserData: this.setUserData,
      clearUserData: this.clearUserData,
      getUserAttribute: this.getUserAttribute(user),
    };

    return (
      <>
        <Helmet>
          <link
            href='https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap'
            rel='stylesheet'
          />
          <script
            type='text/javascript'
            src='https://checkout.epayco.co/checkout.js'
          />
        </Helmet>
        {!isAuthenticating && (
          <ThemeProvider theme={theme}>
            <AuthContext.Provider value={authProps}>
              <ApolloProvider client={clientFactory}>
                <Toast.Provider>
                  <AppRoutes />
                </Toast.Provider>
              </ApolloProvider>
            </AuthContext.Provider>
            <GlobalStyle />
          </ThemeProvider>
        )}
      </>
    );
  }
}

export default App;
