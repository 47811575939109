import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Box } from 'theme-ui';

import { Button, Form, Field, Input, Modal } from 'ui';
import yup from '../located-yup';

const SignupSchema = yup.object().shape({
  oldpassword: yup.string().required(),
  newpassword: yup.string().required(),
  confirmpassword: yup
    .string()
    .required()
    .test(
      'passwords-match',
      'Las contraseñas no coninciden',
      value => this.parent.newpassword === value
    ),
});

function ChangePassword() {
  const { close } = useContext(Modal.Context);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm({
    validationSchema: SignupSchema,
  });

  const onSubmit = async data => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, data.oldpassword, data.newpassword);
      close(true);
    } catch (error) {
      if (error.response && error.response.data) {
        setError('oldpassword', null, error.response.data.message);
      } else {
        console.error(error.response);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} stacked>
      <Box mb='medium'>
        <Field
          control={Input}
          name='oldpassword'
          type='password'
          ref={register}
          placeholder='Contraseña actual'
          error={errors.oldpassword}
        />

        <Field
          control={Input}
          name='newpassword'
          type='password'
          ref={register}
          placeholder='Nueva contraseña'
          error={errors.newpassword}
        />

        <Field
          control={Input}
          name='confirmpassword'
          type='password'
          ref={register}
          placeholder='Confirmar contraseña'
          error={errors.confirmpassword}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          mr='small'
          round
          type='submit'
          size='small'
        >
          Cambiar contraseña
        </Button>
        <Button round onClick={() => close(false)} size='small' variant='secondary'>
          Cancelar
        </Button>
      </Box>
    </Form>
  );
}

export default ChangePassword;
