import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Button, Card, Icon, Paragraph } from 'ui';
import TitleWithIcon from 'payment/components/TitleWithIcon';

const InvoiceSummaryCard = ({ contractNumber, description, url }) => (
  <Card pad='medium' background='white' margin='none' border='bottom'>
    <Box
      __css={{
        display: ['block', 'flex'],
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <TitleWithIcon icon='pdf' label={`Factura de contrato ${contractNumber}`} />
        <Paragraph color='text-xweak' mb={['medium', 0]}>
          {description}
        </Paragraph>
      </Box>

      <Button
        as='a'
        href={url}
        target='_self'
        round
        size='mediumSmall'
        variant='secondary'
        sx={{ flexShrink: 0, ml: [0, 'small'] }}
      >
        <Icon name='document' mr='xsmall' />
        Descargar factura
      </Button>
    </Box>
  </Card>
);

InvoiceSummaryCard.propTypes = {
  contractNumber: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default InvoiceSummaryCard;
