import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

const HelpText = ({ children, ...props }) => (
  <Box {...props}>
    <Box sx={{ fontSize: '13px', color: 'text-xweak', pb: 'small', pt: '4px' }}>
      {children}
    </Box>
  </Box>
);

HelpText.propTypes = {
  children: PropTypes.node.isRequired,
};
export default HelpText;
