import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { Button, Card, Divider, Icon, Loading, Error, Timeline } from 'ui';
import Heading from 'payment/components/Heading';
import { AuthContext } from 'auth/AuthContext';
import Layout from 'payment/components/Layout';
import Link from 'payment/components/Link';
import Canon from 'payment/components/Canon';
import DescriptionList from 'payment/components/DescriptionList';
import { CONTRACTS_QUERY } from 'payment/queries';
import Tabs from 'components/Tabs';
import InvoiceTemp from '../../payment/blocks/InvoiceCard/InvoiceTemp';
import PaymentPlanTable from '../components/PaymentPlanTable';
import AlertMessage from '../components/AlertMessage';
import { getImageFileName } from './PropertiesPage';
import config from '../../../config'

export const formatPrice = (price, symbol = '$') => {
  const formatedPrice = new Intl.NumberFormat('co', {
    style: 'currency',
    currency: 'COP',
  }).format(price);

  const decimal = formatedPrice.substring(formatedPrice.length - 2);
  const decimalString = decimal > 0 ? `,${decimal}` : '';

  return formatedPrice
    .slice(0, -3)
    .replace('COP', symbol)
    .replace(/,/g, '.')
    .concat(decimalString);
};

function PropertiesPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');
  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { buyerGroups } = data.clientByDocument;
  const group = buyerGroups.find(item => item.id === id);

  const filteredPaymentPlans = group?.paymentPlans.filter(paymentPlan => {
    return paymentPlan?.isPaid === false;
  });

  const filteredPaymentPlan = filteredPaymentPlans[0] ?? {};
  const feePayable = group?.feePayable;

  const paymentSummary = group?.paymentSummary;

  const priceMessage = `"El valor del inmueble corresponde al equivalente en pesos colombianos de
  los salarios mínimos legales mensuales vigentes (SMMLV) aprobados por el
  gobierno nacional, para vivienda de interés social (VIS), para el año en
  que se suscribirá la respectiva escritura pública de compraventa. El
  valor propuesto en este plan de pagos corresponde a una proyección que
  podrá tener variaciones dependiendo del valor que fije el Gobierno
  Nacional al salario mínimo para año de la escrituración, esta nota solo
  aplica para proyectos VIS y VIP"`;

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />

      <Divider />

      <Heading
        label={`Proyecto ${group?.mainName}`}
        img={getImageFileName(group?.mainName)}
      />

      {filteredPaymentPlan?.expiresIn5Days && (
        <Card>
          <AlertMessage icon='warning' color='coninsa-warning'>
            ¡Su cuenta de la fecha <strong>{filteredPaymentPlan?.agreedDate}</strong>{' '}
            está por vencer!
          </AlertMessage>
        </Card>
      )}

      {filteredPaymentPlan?.isExpired && (
        <Card>
          <AlertMessage icon='error' color='coninsa-error'>
            ¡Su cuenta de la fecha <strong>{filteredPaymentPlan?.agreedDate}</strong>{' '}
            ha expirado!
          </AlertMessage>
        </Card>
      )}

      {paymentSummary?.totalDaysArrears > 0 && (
        <Card sx={{ bg: '#ffeded' }}>
          <AlertMessage icon='error' color='coninsa-error'>
            <strong>
              ¡Usted tiene acumulado(s) {paymentSummary?.totalDaysArrears} días de
              mora, su saldo es de {formatPrice(paymentSummary?.totalBalance)}!
            </strong>{' '}
            <br />
            Recuerde ponerse al día en sus pagos y evitar cobro de intereses
          </AlertMessage>
        </Card>
      )}

      {group?.units.map(unit => (
        <Card>
          <Card.Header>{`${group?.secondaryName} | Inmueble: ${unit?.name}`}</Card.Header>
          <Box
            __css={{
              display: ['block', 'grid'],
              gap: 'medium',
              gridTemplateColumns: [null, '1fr', null, null, '1fr 3fr'],
              mb: 'small',
            }}
          >
            <Canon label='Valor del inmueble' price={unit?.formattedValue} />

            <Box
              sx={{
                color: 'text-xweak',
                display: ['block', 'none'],
                fontSize: '11px',
                mt: '14px',
              }}
            >
              {priceMessage}
            </Box>
            <Card.Separator sx={{ mt: 'medium', display: [null, 'none'] }} />

            <DescriptionList
              variant='stacked'
              items={[
                { term: 'Tipo de unidad:', details: unit?.unitType },
                { term: 'Área privada:', details: unit?.privateArea },
                { term: 'Área construida:', details: unit?.builtArea },
              ]}
              sx={{
                display: ['block', 'grid'],
                gap: 'small',
                gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
              }}
            />
          </Box>
          <Box
            sx={{
              color: 'text-xweak',
              display: ['none', 'block'],
              fontSize: '12px',
            }}
          >
            {priceMessage}
          </Box>
        </Card>
      ))}

      <Tabs>
        <Tabs.Item icon='inventory' label='Plan de pagos'>
          <PaymentPlanTable group={group} />
          <Box
            sx={{
              mt: ['20px', 'small'],
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              as='a'
              round
              size='mediumSmall'
              href={`${config.drupal.SERVER_URL}/download/payment/plan/${document}/${id}`}
              target='_blank'
              rel='noreferrer'
            >
              <Icon name='fa-reg-file-pdf' sx={{ mr: 'xsmall' }} />
              Generar PDF
            </Button>
          </Box>
        </Tabs.Item>

        <Tabs.Item icon='request' label='Línea de tiempo'>
          <Timeline>
            {group?.procedures.map(procedure => (
              <Timeline.Item
                complianceDate={procedure?.complianceDate}
                commitmentDate={procedure?.commitmentDate}
                label={procedure?.name}
              />
            ))}
          </Timeline>
        </Tabs.Item>

        <Tabs.Item icon='inventory' label='Resumen de pago'>
          <InvoiceTemp orderNumber={group?.orderNumber} invoice={feePayable} />
        </Tabs.Item>
      </Tabs>
    </Layout>
  );
}

export default PropertiesPage;
