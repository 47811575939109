import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { Box } from 'theme-ui';

import Layout from 'payment/components/Layout';
import Widget from 'payment/components/Widget';

const Dashboard = () => {
  return (
    <Layout>
      <Box
        __css={{
          columnGap: 'medium',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          rowGap: 'small',
        }}
      >
        <Widget
          as='a'
          href='https://forms.gle/7CgSxWTceWchmtj18'
          icon='requirements-round'
          label='SOLICITA TU MANTENIMIENTO'
          target='_blank'
        />
        {/* <Widget
          as='a'
          icon='whatsapp'
          label='¡Hablemos!'
          href='https://api.whatsapp.com/send?phone=573173739443&amp;text=Hola%20Eli%2c%20estoy%20en%20b%C3%BAsqueda%20de%20un%20mantenimiento&amp;source=&amp;data='
          target='_blank'
        /> */}

        <Widget
          as={LinkRouter}
          icon='requirements-blue-round'
          label='SOLICITUD ADMINISTRATIVA'
          to='solicitud-requerimientos'
        />
      </Box>
    </Layout>
  );
};

export default Dashboard;
