/** @jsxImportSource theme-ui */
import DefaultSelect from 'react-select';

function Select({ sx = {}, variant = 'default', ...props }) {
  const classNamePrefix = 'select';
  const baseStyles = {
    '& .select__control': {
      minHeight: '44px',
    },

    '& .select__control--is-disabled': {
      borderColor: '#d9dadb !important',
    },

    '& .select__control--is-focused': {
      border: '1px solid #d9dadb !important',
      boxSizing: 'border-box',
      boxShadow: '0 0 0 1px rgb(72, 185, 199)',
    },

    ...sx,
  };

  return (
    <DefaultSelect classNamePrefix={classNamePrefix} {...props} sx={baseStyles} />
  );
}

export default Select;
