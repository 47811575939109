import axios from 'axios';

const saveAs = (fileUrl, name) => {
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.pdf`);
    link.click();
    window.URL.revokeObjectURL(url);
  });
};

export const saveResponseAs = (response, name) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.pdf`);
  link.click();
  window.URL.revokeObjectURL(url);
};

export default saveAs;
