import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ui';
import { Box } from 'theme-ui';

const Link = ({ icon, label, size, ...props }) => {
  const baseStyles = {
    color: '#005fe2',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  const labelStyles = {
    fontSize: 1,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };

  return (
    <Box as='a' variant='default' __css={baseStyles} __themeKey='links' {...props}>
      {icon && <Icon name={icon} />}
      <Box __css={labelStyles} size={size}>
        {label}
      </Box>
    </Box>
  );
};

Link.prototype = {};

Link.defaultProps = {
  size: 'small',
};

Link.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Link;
