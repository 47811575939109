import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import uuid from 'react-uuid';
import { Text } from 'theme-ui';

import { Divider, Error, Loading } from 'ui';
import Link from 'payment/components/Link';
import Heading from 'payment/components/Heading';
import Layout from 'payment/components/Layout';

import ProofCrontractCard from 'payment/blocks/ProofContractCard';

import { AuthContext } from 'auth/AuthContext';

import { CONTRACTS_QUERY } from 'payment/queries';
import LastCertificate from './LastCertificate';
import IncomeCertificateList from './IncomeCertificateList';

const OWNER_ROLE = 'propietario';
const TENANT_ROLE = 'arrendatario';

const getRoleId = role => {
  const map = {
    [OWNER_ROLE]: 'owner',
    [TENANT_ROLE]: 'tenant',
  };

  return map[role] || role;
};

const getBelongsToProp = state => role => prop => {
  const data = state?.belongsTo?.[role];
  return data?.[prop] || [];
};

function Page() {
  const { role } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');
  const isLegalPerson = auth.user.attributes['custom:is_legal_person'];

  const { loading, error, data } = useQuery(CONTRACTS_QUERY, {
    variables: { document },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const { contracts } = data.clientByDocument;

  const roleId = getRoleId(role);
  const proofs = getBelongsToProp(data.clientByDocument)(roleId)('proofs');

  const filteredContracts = contracts.filter(contract => {
    return contract.role === role;
  });

  const filteredContractNumbers = filteredContracts.map(contract => contract.number);

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />
      <Divider />

      <Heading icon='certificates-round' label='Certificados y constancias' />

      {proofs.map(proof => (
        <ProofCrontractCard key={uuid()} {...proof} />
      ))}

      {role === OWNER_ROLE && (
        <>
          <LastCertificate key={uuid()} document={document} />

          <Heading label='Certificados' />

          {filteredContracts.length === 0 && (
            <Text>No se encontró ningún certificado.</Text>
          )}

          <IncomeCertificateList key={uuid()} contracts={filteredContractNumbers} />
        </>
      )}

      {role === TENANT_ROLE && (
        <>
          <Heading label='Certificados registrados'>
            {isLegalPerson === '1' && (
              <Link
                onClick={() => navigate('registro')}
                label='Registrar certificado'
                icon='plus'
              />
            )}
          </Heading>

          <IncomeCertificateList key={uuid()} contracts={filteredContractNumbers} />
        </>
      )}
    </Layout>
  );
}

export default Page;
