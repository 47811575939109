import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import { Loading } from 'ui';

import paymentRoutes from 'payment/routes';
import auhtRoutes from 'auth/routes';
import externalRoutes from '../modules/external/routes';
import quickPaymentRoutes from '../modules/quick-payment/routes';
import ownerSurveyRoutes from '../modules/owner-survey/routes';
import AdvancePaymentRoutes from '../modules/advance-payment/routes';
import certificatiosRoutes from '../modules/certifications/routes';

function Routes() {
  const element = useRoutes([
    ...auhtRoutes,
    ...paymentRoutes,
    ...externalRoutes,
    ...quickPaymentRoutes,
    ...AdvancePaymentRoutes,
    ...ownerSurveyRoutes,
    ...certificatiosRoutes,
  ]);

  return <Suspense fallback={<Loading />}>{element}</Suspense>;
}

export default Routes;
