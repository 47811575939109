import React from 'react';

import Dashboard from 'payment/pages/dashboard';
import Contracts from 'payment/pages/contracts';
import Properties from 'payment/pages/properties';
import Certificates from 'payment/pages/certificates';
import Upload from 'payment/pages/certificates/upload';
import History from 'payment/pages/history';
import ServiceRequestPage from 'payment/pages/service-request';
import Index from 'payment/pages/Index';
import Redirector from 'payment/pages/redirector';
import { Private } from 'routes/utils';
import DeliveryPropertyPage from './pages/delivery-property';
import RequirementsPage from './pages/requirements';
import PropertiesPage from '../properties/pages/PropertiesPage';
import InternalPropertyPage from '../properties/pages/InternalPropertyPage';
import Records from 'payment/pages/records';

import StatisticsPage from '../statistics/app';

const routes = [
  {
    path: 'virtual',
    element: <Private render={Index} />,
    children: [
      { path: '', element: <Redirector /> },
      { path: ':role', element: <Dashboard /> },
      { path: ':role/contratos-asociados', element: <Contracts /> },
      { path: ':role/propiedades-asociadas', element: <Properties /> },
      { path: ':role/facturas/historial', element: <History /> },
      { path: ':role/certificados-constancias', element: <Records /> },
      { path: ':role/certificados', element: <Certificates /> },
      { path: ':role/certificados/registro', element: <Upload /> },
      { path: ':role/solicitud-requerimientos', element: <ServiceRequestPage /> },
      { path: 'estadisticas', element: <StatisticsPage /> },
      { path: 'documentacion', element: <DeliveryPropertyPage /> },
      { path: 'requerimientos', element: <RequirementsPage /> },

      // Propeties routes
      { path: 'mis-inmuebles', element: <PropertiesPage /> },
      { path: 'mis-inmuebles/:id', element: <InternalPropertyPage /> },
    ],
  },
];

export default routes;
