export function getErrorMessage(error) {
  const { code } = error;
  let message;

  switch (code) {
    case 'LimitExceededException':
      message = 'Se excedió el límite de intentos, intente después de un tiempo.';
      break;

    case 'ExpiredCodeException':
      message = 'Se proporcionó un código no válido, solicite un código nuevamente.';
      break;

    case 'CodeMismatch':
      message =
        'El código de verificación incorrecto. Por favor ingrese el código correcto para continuar.';
      break;

    case 'UserNotFoundException':
      message = '';
      break;

    case 'NotAuthorizedException':
      message = 'Su usuario o contraseña son incorrectos.';
      break;

    default:
      message = 'Ocurrió un error inesperado, vuelva a intenar más tarde.';

      // Print error for devs.
      console.error(error);
  }

  return message;
}
