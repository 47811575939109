import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Box, Label, Heading } from 'theme-ui';

import { Button, Form, Field, Input, Message as Error, Select, Checkbox } from 'ui';
import { useStateWithSessionStorage } from 'libs/hooks';
import { GoogleSignInButton, FacebookSignInButton } from 'auth/forms/SocialLogIn';
import validations from 'utils/validations';
import HelpText from '../components/HelpText';
import { t } from '../located-yup';

const options = [
  { value: 'CC', label: 'Cédula de Ciudadanía' },
  { value: 'CE', label: 'Cédula de Extranjería' },
  { value: 'NIT', label: 'NIT' },
  { value: 'PE', label: 'Pasaporte' },
];

function SignUp() {
  const [defaultDocument] = useStateWithSessionStorage('document');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    formState: { isSubmitting },
  } = useForm({
    validateCriteriaMode: 'all',
    defaultValues: {
      document: defaultDocument,
      documentType: '',
    },
  });

  useEffect(() => {
    register(
      { name: 'documentType' },
      {
        required: 'Este campo es obligatorio',
      }
    );
  }, []);

  const onChange = option => {
    setValue('documentType', option.value, true);
  };

  const onSubmit = async ({
    password,
    fullname,
    email,
    documentType,
    document,
    acceptConditions,
    phone,
  }) => {
    try {
      await Auth.signUp({
        username: document.toString(),
        password,
        attributes: {
          name: fullname,
          email,
          'custom:document_type': documentType,
          'custom:document': document.toString(),
          'custom:phone': phone,
          'custom:accept_conditions': acceptConditions ? '1' : '0',
        },
      });

      navigate('/verificar-usuario');
    } catch (error) {
      setError('server', null, t(error.message));
    }
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit(onSubmit)} stacked>
        <Heading>Regístrate</Heading>
        <p>
          Tenga en cuenta, que tu <strong>número de documento</strong> será tu
          usuario de registro.
        </p>

        {errors.server && <Error>{errors.server.message}</Error>}

        <Field
          control={Select}
          name='documentType'
          options={options}
          placeholder='Tipo de documento'
          onChange={onChange}
          error={errors.documentType}
        />

        <Field
          control={Input}
          name='document'
          ref={register({ required: 'Este campo es obligatorio' })}
          placeholder='Ingresa tu número'
          error={errors.document}
        />

        <Field
          control={Input}
          name='fullname'
          ref={register({ required: 'Este campo es obligatorio' })}
          placeholder='Nombre completo'
          error={errors.fullname}
        />

        <Field
          control={Input}
          name='email'
          type='email'
          ref={register({ required: 'Este campo es obligatorio' })}
          placeholder='Correo electrónico'
          error={errors.email}
        />

        <Field
          control={Input}
          name='phone'
          type='tel'
          ref={register}
          placeholder='Número de contacto'
          error={errors.phone}
        />

        <Field
          control={Input}
          name='password'
          type='password'
          ref={register({
            validate: validations.password,
          })}
          placeholder='Contraseña'
          errors={errors}
        >
          <HelpText>
            Ingresa una combinación mínima de ocho caracteres, incluyendo al menos un
            número, letras en mayúscula y minúscula, y un carácter especial (como ! @
            # % &).
          </HelpText>
        </Field>

        <Field error={errors.acceptConditions}>
          <Label>
            <Checkbox
              name='acceptConditions'
              ref={register({
                validate: value => Boolean(value) || 'Este campo es obligatorio',
              })}
            />
            <Box>
              Acepto los
              <Box
                as='a'
                color='accent-1'
                href='https://www.coninsa.co/terminos-y-condiciones-concursos-y-promociones?action'
                target='_blank'
              >
                {' Términos y Condiciones '}
              </Box>
              del sitio web y la
              <Box
                as='a'
                color='accent-1'
                href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                target='_blank'
              >
                {' Política de Tratamiento de Datos de Coninsa Ramón H. S.A.'}
              </Box>
            </Box>
          </Label>
        </Field>

        <Button
          fluid
          disabled={isSubmitting}
          loading={isSubmitting}
          type='submit'
          mb='small'
        >
          Regístrarme
        </Button>
      </Form>

      <GoogleSignInButton label='Regístrate con Facebook' />
      <FacebookSignInButton label='Regístrate con Google' />
    </Box>
  );
}

export default SignUp;
