import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Button, Error, Icon, Loading } from 'ui';

import { GET_OWNER_CANON_URL_QUERY } from '../../queries';

function LazyCanonButton({ contract, document, notify, ...props }) {
  const [getCanon, { loading, error, data, called }] = useLazyQuery(
    GET_OWNER_CANON_URL_QUERY
  );

  const triggerAction = () => {
    if (data) {
      if (data?.url) {
        window.open(data?.url, '_blank');
      } else {
        notify();
      }
    }
  };

  useEffect(
    function triggerFn() {
      triggerAction();
    },
    [data]
  );

  if (loading)
    return (
      <Box
        sx={{
          display: 'inline-block',
          width: ['100%', '156px'],
        }}
      >
        <Loading />
      </Box>
    );

  if (error) return <Error />;

  const handleClick = () => {
    if (!called) {
      getCanon({ variables: { contract, document } });
    } else {
      triggerAction();
    }
  };

  return (
    <Button onClick={handleClick} round size='mediumSmall' {...props}>
      <Icon name='fi-external-link' mr='xsmall' />
      Adelantar canon
    </Button>
  );
}

LazyCanonButton.propTypes = {
  contract: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  notify: PropTypes.func.isRequired,
};

export default LazyCanonButton;
