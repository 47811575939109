import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Divider, Paragraph } from 'ui';
import Link from 'payment/components/Link';
import CertificateRegisterForm from 'payment/forms/CertificateRegisterForm';

import Heading from 'payment/components/Heading';
import Layout from 'payment/components/Layout';

const Upload = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Link onClick={() => navigate(-1)} label='Regresar' icon='arrow-left' />

      <Divider />
      <Heading label='Registro de certificados' icon='certificates-round' />

      <Card background='white'>
        <Paragraph>
          Carga aquí el certificado de retención para que el propietario del inmueble
          que ocupas, pueda acceder a el.
        </Paragraph>

        <CertificateRegisterForm />
      </Card>
    </Layout>
  );
};

export default Upload;
