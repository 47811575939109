import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('layer-root');

class Layer extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.left = 0;
    this.el.style.right = 0;
    this.el.style.top = 0;
    this.el.style.bottom = 0;
    this.el.style.position = 'absolute';
    this.el.style.height = '100%';
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    document.body.style.overflow = 'auto';
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

export default Layer