import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Icon } from 'ui';

function AlertMessage({ color, children, icon, ...props }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} {...props}>
      <Icon
        name={icon}
        sx={{
          color: `${color}`,
          width: ['48px', '30px'],
          height: ['48px', '30px'],
          mr: ['16px', '20px'],
        }}
      />
      <Box sx={{ fontSize: ['14px', '16px'], fontWeight: '400' }}>{children}</Box>
    </Box>
  );
}

AlertMessage.defaultProps = {
  color: undefined,
};

AlertMessage.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
};

export default AlertMessage;
