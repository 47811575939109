import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

import { Loading, Error } from 'ui';
import { AuthContext } from 'auth/AuthContext';
import DocumentCard from './containers/DocumentCard';

import { PROPERTY_DOCUMENTATION_QUERY } from './queries';

function PropertyDocuments({ propertyId }) {
  const auth = useContext(AuthContext);
  const document = auth.getUserAttribute('document');
  const { loading, error, data } = useQuery(PROPERTY_DOCUMENTATION_QUERY, {
    variables: { document, propertyId },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;

  const entities = data.propertyDocumentationQuery.entities || [];

  if (entities.length === 0) {
    return null;
  }

  const { documents } = entities[0];

  return (
    <Box>
      {documents.map(doc => (
        <DocumentCard {...doc} />
      ))}
    </Box>
  );
}

PropertyDocuments.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default PropertyDocuments;
